// PendingPrestations.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../../src/server'; // Adjust the path as necessary
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useTheme,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import StatusButton from 'components/StatusButton';
import { Link } from 'react-router-dom';
import { ArrowLeftSharp } from '@mui/icons-material';

const PendingPrestations = () => {
  const [prestations, setPrestations] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchPrestations = async () => {
      try {
        const response = await axios.get(`${server}/commande/prestations/status/pending`);
        setPrestations(response.data.prestations);
      } catch (error) {
        console.error('Error fetching prestations:', error);
      }
    };

    fetchPrestations();
  }, []);

  const handleStatusChange = async (prestation, newStatus) => {
    try {
      const { commandeId, _id: prestationId } = prestation;
      const response = await axios.put(
        `${server}/commande/update-commande-status/${commandeId}/${prestationId}`,
        { status: newStatus }
      );

      if (response.data.success) {
        toast.success('Le statut a été mis à jour avec succès');
        // Update the local state to reflect the new status
        setPrestations((prevPrestations) =>
          prevPrestations.map((p) =>
            p._id === prestationId ? { ...p, status: newStatus } : p
          )
        );
      } else {
        toast.error('Échec de la mise à jour du statut');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Erreur lors de la mise à jour du statut');
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Liste des commandes en attente
      </Typography>
      <br />
      <Link to={"/commandes"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="prestations table">
          <TableHead>
            <TableRow>
              <TableCell>Date de la prestation</TableCell>
              <TableCell>Nom du client</TableCell>
              <TableCell>Nom de la commande</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prestations.map((prestation) => (
              <TableRow key={prestation._id}>
                <TableCell>
                  {new Date(prestation.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>{prestation.nomClient}</TableCell>
                <TableCell>{prestation.commande}</TableCell>
                <TableCell>{prestation.desc}</TableCell>
                <TableCell>
                  <StatusButton prestation={prestation} onStatusChange={handleStatusChange} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PendingPrestations;
