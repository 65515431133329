import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import logo from "../assets/ILLUSTRATION LOGO BISMOS .png"; // Update with the correct path to your logo file
import { Link } from "react-router-dom";

const EntryPage = () => {
  return (
    <Container sx={{ py: 10 }}>
      <Box display="flex" justifyContent="center" mb={5}>
        <img
          src={logo}
          alt="Bismos High Tech Logo"
          style={{ height: "100px" }}
        />
      </Box>
      <Typography
        variant="h2"
        component="div"
        gutterBottom
        align="center"
        sx={{ color: "success.main", mb: 5 }}
      >
        Bienvenue chez Bismos High Tech, la qualité fait la différence
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "success.light",
              color: "success.contrastText",
              textAlign: "center",
            }}
          >
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Continuer dans Bismos Bureautique
              </Typography>
              <Button
                variant="contained"
                color="success"
                size="large"
                target="_blank" // Open link in new tab
                rel="noopener noreferrer" // Add rel attribute for security
                sx={{ mt: 2 }}
                href="https://bureautique.bismos.com" // Directly navigate to the external link
              >
                Aller dans Bureautique
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "success.light",
              color: "success.contrastText",
              textAlign: "center",
            }}
          >
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Continuer dans Bismos Imprimerie
              </Typography>
              <Button
                variant="contained"
                color="success"
                size="large"
                component={Link}
                to="/login"
                sx={{ mt: 2 }}
              >
                Aller dans l'Imprimerie
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EntryPage;
