import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import { ArrowLeftSharp } from "@mui/icons-material";

const PdfEdit = () => {
  const { id } = useParams();
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const nameUser = data.name;

  const [formData, setFormData] = useState({
    nomClient: "",
    personContact: "",
    personContactNumber: 0,
    priceTotalHorsTax: 0,
    priceTVA: 0,
    priceTTC: 0,
    nameProduct: "",
    qty: 0,
    finalPrice: 0,
    userName: "",
    invoiceNumber: 0,
  });
  useEffect(() => {
    // Calculate finalPrice whenever qty or priceTTC changes
    setFormData((prevState) => ({
      ...prevState,
      finalPrice: Number(formData.qty) * Number(formData.priceTTC),
    }));
  }, [formData.qty, formData.priceTTC]);

  useEffect(() => {
    const fetchPrestation = async () => {
      try {
        const response = await axios.get(`${server}/generate-pdf/${id}`);
        setPdf(response.data);
        // Populate formData with initial values from prestation
        setFormData({
          nomClient: response.data.nomClient,
          personContact: response.data.personContact,
          personContactNumber: response.data.personContactNumber,
          priceTotalHorsTax: response.data.priceTotalHorsTax,
          priceTVA: response.data.priceTVA,
          priceTTC: response.data.priceTTC,
          invoiceNumber: response.data.invoiceNumber,
          userName: nameUser,
        });
      } catch (error) {
        setError("Error fetching prestation");
      } finally {
        setLoading(false);
      }
    };

    fetchPrestation();
  }, [id]);

  // console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${server}/final-pdf/create-final-pdf`, {
        formData,
      });
      toast.success("Final PDF generated successful");
      setTimeout(() => {
        window.location.replace("/facture");
      }, 2000);
    } catch (err) {
      // console.log(err);
      toast.error(err.response.data.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {pdf ? (
        <div>
          <Box m="20px">
            <FlexBetween>
              <Link to={"/pdf"}>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginBottom: "6px" }}
                >
                  <ArrowLeftSharp />
                </Button>
              </Link>
              <Header title="FINALISATION DE LA FACTURE" />
            </FlexBetween>
            {/* <Grid style={{ display: "flex", marginTop: "10px" }}>
              <Button
                color="secondary"
                variant="contained"
                style={{
                  marginBottom: "6px",
                  marginRight: "30px",
                  fontWeight: "bold",
                }}
                endIcon={<SendIcon />}
              >
                {" "}
                <Link
                  to="/facture"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Voir les factures finales
                </Link>
              </Button>
            </Grid> */}

            <Formik>
              {({ values, errors, touched, handleBlur, handleChange }) => (
                <form>
                  <Box
                    display="grid"
                    marginTop="20px"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`Nom du produit, livre ou service `}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          nameProduct: e.target.value,
                        })
                      }
                      name="Nom du produit, livre ou service"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`Quantité total`}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          qty: parseInt(e.target.value),
                        })
                      }
                      name="Nom"
                      sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      readOnly // Make this field read-only
                      label={`Nom du client : ${pdf.nomClient}`}
                      value={pdf.nomClient} // Use pdf.nomClient directly
                      name="Nom du client"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly // Make this field read-only
                      type="string"
                      label={`Personne de contact : ${pdf.personContact}`}
                      value={pdf.personContact} // Use pdf.personContact directly
                      name="Personne de contact"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly
                      type="string"
                      label={`Numero du personne de contact : ${pdf.personContactNumber} `}
                      value={pdf.personContactNumber}
                      name="Numero de contact"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly
                      type="number"
                      label={`Prix total hors tax : ${pdf.priceTotalHorsTax} `}
                      value={pdf.priceTotalHorsTax}
                      name="Prix total hors tax"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly
                      type="number"
                      label={`le TVA 18% : ${pdf.priceTVA} `}
                      value={pdf.priceTVA}
                      name=" le TVA 18%"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly
                      type="number"
                      label={`le TTC : ${pdf.priceTTC} `}
                      value={pdf.priceTTC}
                      name="TTC"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      readOnly
                      type="number"
                      label={`Prix total (PV) : ${formData.finalPrice} `}
                      value={formData.finalPrice}
                      name="Prix total (PV)"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Créer la facture final
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      ) : (
        <div>No pdf found</div>
      )}
    </div>
  );
};

export default PdfEdit;
