import React, { useEffect, useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import { Button, IconButton } from "@mui/material";

const FormRetrait = ({ setOpenPopup }) => {
  const [desc, setDesc] = useState("");
  const [products, setProducts] = useState([]);
  const [userId, setUserId] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [tableData, setTableData] = useState([
    { id: 1, nom: "", description: "", prix: 0, quantite: 0 },
  ]);

  const handleChange = (setter) => (event) => setter(event.target.value);

  const handleAddRow = () => {
    setTableData([
      ...tableData,
      {
        id: tableData.length + 1,
        nom: "",
        description: "",
        prix: 0,
        quantite: 0,
      },
    ]);
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, index) => index !== rowIndex));
    }
  };

  const handleRowChange = (rowIndex, key) => (event) => {
    const value = parseFloat(event.target.value) || 0;
    const updatedData = [...tableData];
    updatedData[rowIndex][key] = value;
    setTableData(updatedData);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const getUserIdFromStorage = async () => {
      const storedUserId = localStorage.getItem("secretKeyesforzado7");
      if (storedUserId) {
        setUserId(storedUserId);
      }
    };

    getUserIdFromStorage();
  }, []);

  const handleProductSelection = (rowIndex) => async (event) => {
    const updatedData = [...tableData];
    const selectedProductId = event.target.value;
    updatedData[rowIndex].item = selectedProductId;

    try {
      const response = await axios.get(
        `${server}/product/a-product/${selectedProductId}`
      );
      const product = response.data.products;
      updatedData[rowIndex].description = product.desc;
      setTableData(updatedData);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleRetrait = async (event) => {
    event.preventDefault();
    const formData = {
      userId,
      products: tableData.map((item) => ({
        productId: item.item,
        quantity: item.quantite,
      })),
      desc,
    };

    try {
      const response = await axios.post(
        `${server}/retrait/api/retrait`,
        formData,
        { withCredentials: true }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.replace("/stocks");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header text-center">
          <h4>Retrait des produits</h4>
          <IconButton onClick={() => setOpenPopup(false)}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setOpenPopup(false)}
            >
              X
            </Button>
          </IconButton>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              <div className="input-group mb-3">
                <span className="input-group-text">Description</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Description"
                  value={desc}
                  onChange={handleChange(setDesc)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input-group mb-3">
                <span className="input-group-text">Inv. Date</span>
                <input
                  type="date"
                  className="form-control"
                  value={invoiceDate}
                  onChange={handleChange(setInvoiceDate)}
                />
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom du produit</th>
                <th scope="col" className="text-end">
                  Description
                </th>
                <th scope="col" className="text-end">
                  Quantite
                </th>
                <th scope="col">
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={handleAddRow}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={row.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <select
                      className="form-control"
                      value={row.item}
                      onChange={handleProductSelection(index)}
                    >
                      <option value="">--Select Product--</option>
                      {products.map((product) => (
                        <option key={product._id} value={product._id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.description}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control text-end"
                      value={row.quantite}
                      onChange={handleRowChange(index, "quantite")}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeleteRow(index)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row">
            <div className="col-8">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleRetrait}
              >
                Retire
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormRetrait;
