import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";

const FormNumerotation = ({ openPopup, setOpenPopup }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formData, setFormData] = useState({
    perforationPrice: 0,
    numerotationPrice: 0,
  });

  useEffect(() => {
    const fetchImpression = async () => {
      try {
        const IdImpression = localStorage.getItem("impressionId");
        const response = await axios.get(
          `${server}/impression/impression/${IdImpression}`
        );
        const { perforationPrice, numerotationPrice } = response.data;
        setFormData({
          perforationPrice,
          numerotationPrice,
        });
      } catch (error) {
        console.error("Error fetching impression details:", error);
        setError("Error fetching impression details");
      } finally {
        setLoading(false);
      }
    };

    fetchImpression();
  }, []);

  const handleUpdate = async () => {
    try {
      const IdImpression = localStorage.getItem("impressionId");
      await axios.put(
        `${server}/impression/impression/${IdImpression}`,
        formData
      );
      toast.success("Impression updated successfully");
      setTimeout(() => {
        window.location.replace("/devis");
      }, 1000);
    } catch (error) {
      console.error("Error updating impression:", error);
      toast.error("Failed to update impression");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div>
        <Box m="20px">
          <FlexBetween>
            <Header title="CHANGER LES PRIX DE PERFORATION ET NUMEROTATION" />
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setOpenPopup(false)}
            >
              X
            </Button>
          </FlexBetween>

          <Formik>
            {({ values, errors, touched, handleBlur, handleChange }) => (
              <form>
                <Box
                  display="grid"
                  marginTop="20px"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label={`Prix de perforation : ${formData.perforationPrice} `}
                    value={formData.perforationPrice}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        perforationPrice: parseInt(e.target.value),
                      }))
                    }
                    name="Prix de perforation"
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label={`Prix de numérotation : ${formData.numerotationPrice} `}
                    value={formData.numerotationPrice}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        numerotationPrice: parseInt(e.target.value),
                      }))
                    }
                    name="Prix de numérotation"
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    Mettre à jour ces prix
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};

export default FormNumerotation;
