// StatusButton.js
import React, { useState } from 'react';
import { Button, Menu, MenuItem, useTheme } from '@mui/material';

const StatusButton = ({ prestation, onStatusChange }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    not_paid_delivered: theme.palette.grey[500],
    production: theme.palette.info.main,
    pending: theme.palette.secondary.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: 'Livré',
    not_delivered: 'Non livré',
    half_delivered: 'Partiellement livré',
    not_paid_delivered: 'Livré sans paiement',
    production: 'En production',
    pending: 'En attente',
    not_production: 'Non en production',
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus) => {
    onStatusChange(prestation, newStatus);
    handleClose();
  };

  const currentStatus = prestation.status;

  return (
    <>
      <Button
        variant="contained"
        style={{ backgroundColor: statusColors[currentStatus] }}
        onClick={handleClick}
      >
        {statusLabels[currentStatus]}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.keys(statusLabels).map((status) => (
          <MenuItem key={status} onClick={() => handleStatusChange(status)}>
            {statusLabels[status]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusButton;
