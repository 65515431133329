import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import PopupConfirm from "components/PopupConfirm";
import { Link } from "react-router-dom";
import { ArrowLeftSharp, DeleteOutline } from "@mui/icons-material";
import { CheckBox } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { server } from "server";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";

const PdfCommande = () => {
  const theme = useTheme();
  const [pdfs, setPdfs] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [open, setOpen] = useState(false);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  // Function to handle PDF deletion
  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer ce PDF ?")) {
        const response = await axios.delete(
          `${server}/generate-pdf-commande/delete-pdf-commande/${id}`
        );
        if (response.data.success) {
          window.location.reload(true);
        } else {
          // Handle deletion failure if needed
        }
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
      toast.error("Une erreur s'est produite lors de la suppression du PDF.");
    }
  };

  // Fetch PDFs on component mount
  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const res = await axios.get(`${server}/generate-pdf-commande/pdfs`);
        setPdfs(res.data);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPDFs();
  }, []);

  // Function to handle viewing PDF details and generating PDF
  const handleViewPaymentDetails = async (id) => {
    try {
      const response = await axios.get(
        `${server}/generate-pdf-commande/PdfCommande/${id}`
      );
      const pdfData = response.data;

      const doc = new jsPDF();

      // Set document properties (optional)
      doc.setProperties({
        title: `proforma-${pdfData.nomClient}`,
        subject: "Facture commande",
        author: "Bismos",
      });

      // Add logo (if available)
      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.setFontSize(10);
      doc.text("A. Vendeur: BISMOS NIF: 400045190/RC:03436", 10, businessY + 5);
      doc.text(
        "Avenue: KIGARAMA, Ngozi, N0: 21, centre Fiscal:Ressort Nord",
        10,
        businessY + 10
      );
      doc.text(
        "Secteur d'activité: COM. General Forme Juridique: Personne Morale",
        10,
        businessY + 15
      );

      // Invoice Details
      const invoiceY = businessY + 10;
      doc.setFontSize(12);
      doc.text("     Facture", doc.internal.pageSize.getWidth() - 70, 20);
      doc.text(`   `, 10, invoiceY + 5);
      doc.text(`Nom du client: ${pdfData.nomClient}`, 10, invoiceY + 10);
      doc.text(
        `Numero de contact: ${pdfData?.ContactNumber}`,
        10,
        invoiceY + 15
      );

      // Add checkboxes below Numero de contact
      const checkboxY = invoiceY + 20; // Adjust Y position as needed
      doc.setFontSize(10);
      doc.text("assujetti à la TVA : ", 10, checkboxY + 2);
      doc.rect(40, checkboxY - 2, 5, 5); // Checkbox 1
      doc.text("Oui", 45, checkboxY + 2);
      doc.rect(52, checkboxY - 2, 5, 5); // Checkbox 2
      doc.text("Non", 57, checkboxY + 2);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );
      doc.text(
        `No de la commande: ${pdfData.invoiceNumber}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY + 5
      );

      // Table for Item Details
      const tableY = checkboxY + 20;
      const headers = [
        "Nom de la commande",
        "Description",
        "Quantité",
        "Prix unitaire",
        "Prix total",
      ];
      const options = {
        startY: tableY,
        head: [headers],
        body: pdfData.items.map((item) => [
          item.item,
          item.description,
          item.prix,
          item.nombre_page,
          item.color,
        ]),
        styles: {
          fontSize: 10,
          cellPadding: 2,
          overflow: "linebreak",
          halign: "left",
        },
        columnWidth: [80, 40, 80],
      };
      doc.autoTable(options);

      // Total Price
      const totalY = doc.autoTable.previous.finalY + 10;
      doc.setFontSize(10);
      doc.text(
        `Prix Total des commandes: ${pdfData.priceTotalPriceTotal} fbu`,
        10,
        totalY
      );

      const textY = tableY + 50;
      doc.text(
        "Pour la compabilite",
        doc.internal.pageSize.getWidth() - 65,
        textY,
        {
          align: "center",
        }
      );

      // Footer Text
      const footerY = doc.internal.pageSize.getHeight() - 20;
      const footerText =
        "Tel: 79 967 624 / 22303165 ||KCB:06691079043 ,Bancobu :20002-11109-06571220101-09";
      const footerText2 = "www.bismos.com || E-mail: info@bismos.com";
      doc.setFont("helvetica", "normal");
      doc.text(footerText, doc.internal.pageSize.getWidth() / 2, footerY, {
        align: "center",
      });
      doc.text(footerText2, doc.internal.pageSize.getWidth() / 2, footerY + 5, {
        align: "center",
      });

      setPdfDoc(doc);
      setPdf(pdfData);
      setOpen(true);
    } catch (err) {
      console.error("Error generating PDF:", err);
      toast.error("Une erreur s'est produite lors de la génération du PDF.");
    }
  };

  // Function to handle downloading the generated PDF
  const handleDownloadPDF = () => {
    if (pdfDoc) {
      pdfDoc.save(`commande_${pdf?.nomClient}_${pdf?.invoiceNumber}.pdf`);
    } else {
      console.error("PDF not yet generated");
      toast.warn("Aucun PDF disponible pour le téléchargement.");
    }
  };

  // Columns configuration for DataGrid
  const columns = [
    { field: "id", headerName: "Id de commande", flex: 2 },
    { field: "nomClient", headerName: "Nom du client", flex: 2 },
    { field: "url", headerName: "Nom du facture", flex: 2 },
    { field: "quantity", headerName: "Quantité", flex: 1 },
    { field: "unitPrice", headerName: "Prix unitaire", flex: 1 },
    { field: "totalPrice", headerName: "Prix total", flex: 1 },
    { field: "userName", headerName: "Fait Par", flex: 1 },
    { field: "createdAt", headerName: "Crée le", flex: 1.5 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setOpen(!open) || handleViewPaymentDetails(params.row.id)
            }
          >
            <img
              src={pdfPicture}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                marginRight: "10px",
                transition: "transform 0.3s",
              }}
            />
          </div>
          {data?.role === "superadmin" && (
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Les factures des commandes"
        subtitle="La liste des factures des commandes"
      />
      <br />
      <Link to="/comptabilité">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <br />
      <Grid style={{ display: "flex", marginTop: "10px" }}>
        {/* Optional button to view final invoices */}
        {/* <Button
          color="secondary"
          variant="contained"
          style={{
            marginBottom: "6px",
            marginRight: "30px",
            fontWeight: "bold",
          }}
          endIcon={<SendIcon />}
        >
          {" "}
          <Link
            to="/facture"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Voir les factures finales
          </Link>
        </Button> */}
      </Grid>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            fontWeight: 600,
            textTransform: "uppercase",
          },
          "& .MuiDataGrid-columnsContainer": { borderBottom: "none" },
          "& .MuiDataGrid-columnSeparator": { display: "none" },
        }}
      >
        <DataGrid
          rows={pdfs.map((pdf) => ({
            id: pdf._id,
            nomClient: pdf.nomClient,
            url: pdf.url,
            quantity: pdf.quantity,
            unitPrice: pdf.unitPrice,
            totalPrice: pdf.totalPrice,
            userName: pdf.userName,
            createdAt: pdf.createdAt?.slice(0, 10),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbar }}
        />
      </Box>
      <PopupConfirm
        title="Voulez-vous télécharger ce fichier ?"
        open={open}
        setOpen={setOpen}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => {
              setOpen(false);
              handleDownloadPDF();
            }}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen(false)}
          >
            X
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default PdfCommande;
