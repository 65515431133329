import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormAddComponentPayment = ({ openPopup, setOpenPopup, id }) => {
  const [nomClient, setNomClient] = useState("");
  const [contact, setContact] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paidAmount, setPaidAmount] = useState(0);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/commande/${id}`);
        const nomClient1 = response.data.nomClient;
        const contact1 = response.data.contact;
        setNomClient(nomClient1);
        setContact(contact1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${server}/payment-commande/create-payment-history-all`,
        {
          commandeId: id, // Add the ID of the commande here
          nomClient,
          userName: data.name,
          contact,
          paymentMethod,
          paidAmount: parseFloat(paidAmount), // Convert to number
        },
        { withCredentials: true }
      );

      if (response.data.message) {
        toast.success(response.data.message);
        window.location.reload(true);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error creating payment history:", error);
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="AJOUTER UN AUTRE PAIEMENT" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <Formik>
        {({ handleBlur }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Nom du client"
                onBlur={handleBlur}
                value={nomClient}
                onChange={(e) => setNomClient(e.target.value)}
                name="nomClient"
                sx={{ gridColumn: "span 2" }}
                disabled
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Numero du client"
                onBlur={handleBlur}
                onChange={(e) => setContact(e.target.value)}
                value={contact}
                name="contact"
                sx={{ gridColumn: "span 2" }}
                disabled
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="La somme payée"
                onBlur={handleBlur}
                onChange={(e) => setPaidAmount(e.target.value)}
                value={paidAmount}
                name="La somme payée"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                select
                fullWidth
                variant="filled"
                label="Methode de paiement de l'avance"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                name="paymentMethod"
              >
                <MenuItem value="BANCOBU">BANCOBU</MenuItem>
                <MenuItem value="BCB">BCB</MenuItem>
                <MenuItem value="KCB">KCB</MenuItem>
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="IBB">IBB</MenuItem>
              </TextField>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button color="secondary" variant="contained" type="submit">
                Ajouter une autre somme
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  Nom: yup.string().required("required"),
  MontantReel: yup.string().required("required"),
  total: yup.string().required("required"),
});
const initialValues = {
  Nom: "",
  interieur: "",
  Couverture: "",
  rabatintercalaire: "",
  total: "",
  MontantReel: "",
};

export default FormAddComponentPayment;
