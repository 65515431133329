import React from "react";
import { AddBox } from "@mui/icons-material"; // Import AddBox for the create icon
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { Link } from "react-router-dom";

const DataGridCustomToolbarCommande = ({ setOpenPopup, openPopup }) => {
  return (
    <FlexBetween>
      <GridToolbar />
      
      <Button
        color="secondary"
        variant="contained"
        style={{ marginBottom: "6px" }}
        onClick={() => setOpenPopup(!openPopup)}
      >
        <AddBox /> Ajouter un nouveau client
      </Button>
    </FlexBetween>
  );
};

export default DataGridCustomToolbarCommande;
