import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import { ArrowLeftSharp, RemoveRedEyeOutlined } from "@mui/icons-material";

const History = () => {
  const theme = useTheme();
  const [allpayment, setAllpayment] = useState([]);

  useEffect(() => {
    axios.get(`${server}/loan/get-all-payments`).then((res) => {
      setAllpayment(res.data.allPayments);
    });
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Paiement",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Paiement du:",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/print/" + params.row.id}>
              <RemoveRedEyeOutlined />
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  allpayment &&
    allpayment.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        createdAt: item.createdAt.slice(0, 16),
      });
    });
  return (
    <Box m="1.5rem 2.5rem">
      <Link to={"/employés"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <Header
        title="Historique des paiement"
        subtitle="Les liste des paiments"
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default History;
