import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import { server } from "../../src/server";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [prestationId, setPrestationId] = useState("");
  const [paperId, setPaperId] = useState("");
  const [impressionId, setImpressionId] = useState("");
  const [paperCoverId, setPaperCoverId] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        `${server}/user/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        const userId = localStorage.setItem(
          "secretKeyesforzado7",
          res.data.user._id
        );
        console.log(res.data.user._id);

        toast.success("Login successful");
        setTimeout(() => {
          window.location.replace("/acceuil");
        }, 1000); // Display success message
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Please Provide the correct Password"); // Display error message
      });
  };

  //grab prestations id
  useEffect(() => {
    axios
      .get(`${server}/prestation/`)
      .then((res) => {
        setPrestationId(res.data.prestations._id);
        const prestationId = localStorage.setItem(
          "prestationId",
          res.data.prestations._id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //grab depense id
  useEffect(() => {
    axios
      .get(`${server}/depense/`)
      .then((res) => {
        // setPrestationId(res.data.prestations._id);
        const prestationId = localStorage.setItem(
          "depenseId",
          res.data.prestations._id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //grabing paper id
  useEffect(() => {
    axios
      .get(`${server}/papier/`)
      .then((res) => {
        setPaperId(res.data.papiers._id);
        const papierId = localStorage.setItem("papierId", res.data.papiers._id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //grabing impression id
  useEffect(() => {
    axios
      .get(`${server}/impression/`)
      .then((res) => {
        setImpressionId(res.data.impressions._id);
        const impressionId = localStorage.setItem(
          "impressionId",
          res.data.impressions._id
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //grabing paperCover id
  useEffect(() => {
    axios
      .get(`${server}/papier/couverture`)
      .then((res) => {
        setPaperCoverId(res.data.papiers._id);
        const paperCoverId = localStorage.setItem(
          "paperCoverId",
          res.data.papiers._id
        );
        console.log(paperCoverId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Login</h2>
        </Grid>
        <TextField
          label="Username"
          placeholder="Enter username"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          placeholder="Enter password"
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox name="checkedB" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={handleSubmit}
        >
          Sign in
        </Button>
        <Typography>
          <Link href="#">Forgot password?</Link>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Login;