import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'


const PopupPdf = (props) => {
    const {title , children, openPdf} = props

    return (
      <div>
          <Dialog open ={openPdf}>
              <DialogTitle>
                  {title}
              </DialogTitle>
              <DialogContent>
                  {children}
              </DialogContent>
          </Dialog>
      </div>
    )
}

export default PopupPdf