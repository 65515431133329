import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormEmployee from "scenes/formemployee";
import Popup from "components/Popup";
import {
  ArrowLeftSharp,
  CancelPresentationSharp,
  CheckBox,
  DeleteOutline,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./loan.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";

const Loan = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [loan, setLoan] = useState([]);
  const [userId, setUserId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    axios.get(`${server}/loan/get-all-loans`).then((res) => {
      setLoan(res.data.allloans);
    });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${server}/loan/delete-loan/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const columns = [
    { field: "createdAt", headerName: "donneé le", flex: 1 },
    {
      field: "name",
      headerName: "Nome et Prenom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "credit",
      headerName: "credit",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "numMonths",
      headerName: "Mois a payer",
      flex: 1,
    },
    {
      field: "remainedCredits",
      headerName: "Le credit restant",
      flex: 1,
    },
    {
      field: "monthlyPayment",
      headerName: "Le payment par mois ",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.row.status === "pending" ? (
              <span style={{ color: "orange" }}>En attente</span>
            ) : (
              <span style={{ color: "#0af116" }}>Payé</span>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <DeleteOutline
              className="userListDelete"
              onClick={() =>
                setUserId(params.row.id) || setOpenDelete(!openDelete)
              }
            />
          </>
        );
      },
    },
  ];

  const row = [];

  loan &&
    loan.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        credit: formatNumber(item.credit),
        numMonths: formatNumber(item.numMonths),
        remainedCredits: formatNumber(item.remainedCredits),
        monthlyPayment: formatNumber(item.monthlyPayment),
        status: item.status,
        createdAt: item.createdAt?.slice(0, 10),
      });
    });

  return (
    <Box m="20px">
      <Link to={"/employés"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <Header title="Crédits" subtitle="Liste des credits" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <PopupConfirm
        title="Voulez-vous supprimer le Credit "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(userId)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <CancelPresentationSharp />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default Loan;
