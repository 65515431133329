import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import itemsData from "../../data/itemsData";
import FormPrestation from "scenes/formPrestation";
import { useGetPrestationsQuery } from "state/api";
import axios from "axios";
import { server } from "../../../src/server";
import { ArrowLeftSharp } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";

const Prices = () => {
  const theme = useTheme();
  const { data } = useGetPrestationsQuery();

  // console.log(data);

  const [prestations, setPrestations] = useState([]);
  const IdPrestation = localStorage.getItem("prestationId");

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/prestation/get-prestations`
        );
        const prestationsArray = response.data.prestations || []; // Access prestations array directly or use empty array if not available
        setPrestations(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // values to be sent to the backend
  // const [items, setItems] = useState(itemsData);

  const [openPopup, setOpenPopup] = useState(false);

  //   const { data, isLoading } = useGetTransactionsQuery({
  //     page,
  //     pageSize,
  //     sort: JSON.stringify(sort),
  //     search,
  //   });

  //delete item

  const handleDelete = async (prestationsId) => {
    try {
      const IdPrestation = await localStorage.getItem("prestationId");
      if (window.confirm("Are you sure you want to delete this prestation?")) {
        const response = await axios.delete(
          `${server}/prestation/delete-prestation/${IdPrestation}/${prestationsId}`
        );
        // Assuming your backend returns a success message upon deletion
        if (response.data.success) {
          // Refetch data after deletion or update UI as needed
          window.location.replace("/prix");
          // Optionally, you can navigate to a different route upon successful deletion
          // history.push("/some-route");
        } else {
          // Handle deletion failure
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Prestation",
      flex: 3,
    },
    {
      field: "interieur",
      headerName: "interieur",
      flex: 1,
    },
    {
      field: "couverture",
      headerName: "couverture",
      flex: 1,
    },
    {
      field: "rabatintercalaire",
      headerName: "rabatintercalaire",
      flex: 1,
    },
    {
      field: "total",
      headerName: "total",
      flex: 1,
    },
    {
      field: "montantReel",
      headerName: "montantReel",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/prix/${IdPrestation}/` + params.row.id}>
              <button className="userListEdit">Edit</button>
            </Link>
            {
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            }
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Link to={"/devis"}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <ArrowLeftSharp />
          </Button>
        </Link>
        <Header title="Les prestations" />
      </FlexBetween>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          //   loading={isLoading || !data}
          //   getRowId={(row) => row._id}

          rows={prestations.map((prestation) => ({
            id: prestation._id, // Assuming _id is the unique identifier
            name: prestation.name,
            interieur: prestation.interieur,
            couverture: prestation.couverture,
            rabatintercalaire: prestation.rabatintercalaire,
            total: prestation.total,
            montantReel: prestation.montantReel,
          }))}
          columns={columns}
          pageSize={8}
          //   rowCount={(data && data.total) || 0}
          //   rowsPerPageOptions={[20, 50, 100]}
          //   pagination
          //   page={page}
          //   pageSize={pageSize}
          //   paginationMode="server"
          //   sortingMode="server"
          //   onPageChange={(newPage) => setPage(newPage)}
          //   onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //   onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Ajouter une nouvelle prestation"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormPrestation setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default Prices;
