import React from "react";
import { AddBox } from "@mui/icons-material"; // Import AddBox for the create icon
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";

const DataGridCustomToolbar = ({ setOpenPopup, openPopup }) => {
  return (
    <FlexBetween>
      <GridToolbar />
      <Button
        color="secondary"
        variant="contained"
        style={{ marginBottom: "6px" }}
        onClick={() => setOpenPopup(!openPopup)}
      >
        <AddBox /> Ajouter une autre commande
      </Button>
    </FlexBetween>
  );
};

export default DataGridCustomToolbar;
