import { Box, Button, TextField, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";

const FormLoan = ({ setOpenPopup, employeeName, id, employeeSal }) => {
  const [name, setName] = useState(employeeName);
  const [credit, setCredit] = useState();
  const [numMonths, setNumMonths] = useState();

  useEffect(() => {
    if (credit && employeeSal) {
      const calculatedMonths = (credit * 24) / (employeeSal * 0.3 * 24);

      if (calculatedMonths % 2 !== 0) {
        const precision = calculatedMonths.toFixed(1);
        const finalNumMonths = Math.ceil(precision);
        setNumMonths(finalNumMonths);
      } else {
        setNumMonths(calculatedMonths);
      }
    }
  }, [credit, employeeSal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formInfo = {
        name,
        credit,
      };
      const res = await axios.post(`${server}/loan/loans/${id}`, formInfo, {
        withCredentials: true,
      });
      toast.success("crediter avec success");
      setTimeout(() => {
        window.location.replace("/crédits");
      }, 1000);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="Credite un employee" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="credit"
            value={credit}
            onChange={(e) => setCredit(e.target.value)}
          />
          <TextField
            type="number"
            fullWidth
            label="Les Mois a payer"
            value={numMonths}
            disabled
          ></TextField>
        </Box>
        <Box mt="20px">
          <Button type="submit" variant="contained" color="primary">
            Credité
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormLoan;
