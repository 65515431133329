import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";

const PriceEdit = () => {
  const { id } = useParams();
  const [prestation, setPrestation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formData, setFormData] = useState({
    name: "",
    interieur: 0,
    couverture: 0,
    rabatintercalaire: 0,
    total: 0,
    montantReel: 0,
  });

  useEffect(() => {
    const fetchPrestation = async () => {
      const IdPrestation = localStorage.getItem("prestationId");
      try {
        const response = await axios.get(
          `${server}/prestation/get-prestation/${IdPrestation}/${id}`
        );
        setPrestation(response.data.prestation);
        // Populate formData with initial values from prestation
        setFormData({
          name: response.data.prestation.name,
          interieur: response.data.prestation.interieur,
          couverture: response.data.prestation.couverture,
          rabatintercalaire: response.data.prestation.rabatintercalaire,
          total: response.data.prestation.total,
          montantReel: response.data.prestation.montantReel,
        });
      } catch (error) {
        setError("Error fetching prestation");
      } finally {
        setLoading(false);
      }
    };

    fetchPrestation();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const IdPrestation = localStorage.getItem("prestationId");
      const response = await axios.put(
        `${server}/prestation/update-prestation/${IdPrestation}/${id}`,
        formData
      );
      toast.success("Prestation updated successful");
      setTimeout(() => {
        window.location.replace("/prix");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {prestation ? (
        <div>
          <Box m="20px">
            <FlexBetween>
              <Header title="LES DETAILS DE LA PRESTATION" />
            </FlexBetween>

            <Formik>
              {({ values, errors, touched, handleBlur, handleChange }) => (
                <form onSubmit={handleUpdate}>
                  <Box
                    display="grid"
                    marginTop="20px"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`Nom : ${prestation.name} `}
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      name="Nom"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`interieur : ${prestation.interieur} `}
                      value={formData.interieur}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          interieur: parseInt(e.target.value),
                        })
                      }
                      name="interieur"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`couverture : ${prestation.couverture} `}
                      value={formData.couverture}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          couverture: parseInt(e.target.value),
                        })
                      }
                      name="couverture"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`rabatintercalaire : ${prestation.rabatintercalaire} `}
                      value={formData.rabatintercalaire}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          rabatintercalaire: parseInt(e.target.value),
                        })
                      }
                      name="rabatintercalaire"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`total : ${prestation.total} `}
                      value={formData.total}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          total: parseInt(e.target.value),
                        })
                      }
                      name=" total"
                      sx={{ gridColumn: "span 4" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`Montant Reel : ${prestation.montantReel} `}
                      value={formData.montantReel}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          montantReel: parseInt(e.target.value),
                        })
                      }
                      name="MontantReel"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button color="secondary" variant="contained" type="submit">
                      Mettre à jour la prestation
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      ) : (
        <div>No prestation found</div>
      )}
    </div>
  );
};

export default PriceEdit;
