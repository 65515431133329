import { Box, Button, IconButton, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { PriceChange, Try } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const FormPrestation = ({ openPopup, setOpenPopup }) => {
  const [name, setName] = useState("");
  const [interieur, setInterieur] = useState(0);
  const [couverture, setCouverture] = useState(0);
  const [rabatintercalaire, setRabatintercalaire] = useState(0);
  const [total, setTotal] = useState(0);
  const [montantReel, setMontantReel] = useState(0);
  const [id, setId] = useState("");

  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    const res = async (e) => {
      e.preventDefault();
      await axios
        .get(`${server}/prestation/get-prestations`)
        .then((res) => {
          console.log(res.data);
          const prestationId = localStorage.setItem(
            "prestationId",
            res.data._id
          );
          setId(prestationId);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, []);

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !name ||
        !interieur ||
        !couverture ||
        !rabatintercalaire ||
        !total ||
        !montantReel
      ) {
        toast.error("Veuillez remplir tous les champs");
      }
      const IdPrestation = await localStorage.getItem("prestationId");
      const res = await axios.put(
        `${server}/prestation/add-prestation/${IdPrestation}`,
        {
          name,
          interieur,
          couverture,
          rabatintercalaire,
          total,
          montantReel,
        }
      );
      console.log(res.data);
      window.location.replace("/prix");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="AJOUTER UNE PRESTATION" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(false)}
        >
          X
        </Button>
      </FlexBetween>

      <Formik

      // initialValues={initialValues}
      // validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Nom"
                onBlur={handleBlur}
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="Nom"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="interieur"
                onBlur={handleBlur}
                onChange={(e) => setInterieur(e.target.value)}
                value={interieur}
                name="interieur"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="couverture"
                onBlur={handleBlur}
                onChange={(e) => setCouverture(e.target.value)}
                value={couverture}
                name="couverture"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="rabatintercalaire"
                onBlur={handleBlur}
                onChange={(e) => setRabatintercalaire(e.target.value)}
                value={rabatintercalaire}
                name="rabatintercalaire"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="total"
                onBlur={handleBlur}
                onChange={(e) => setTotal(e.target.value)}
                value={total}
                name=" total"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="MontantReel"
                onBlur={handleBlur}
                onChange={(e) => setMontantReel(e.target.value)}
                value={montantReel}
                name="MontantReel"
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Ajouter une prestation
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  Nom: yup.string().required("required"),

  // contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid")
  //   .required("required"),
  MontantReel: yup.string().required("required"),
  total: yup.string().required("required"),
});
const initialValues = {
  Nom: "",
  interieur: "",
  Couverture: "",
  rabatintercalaire: "",
  total: "",
  MontantReel: "",
};

export default FormPrestation;
