import { Box, Button, TextField, IconButton } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormProductStock = ({ setOpenPopup }) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [stock, setStock] = useState();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("secretKeyesforzado7");
    try {
      const formInfo = {
        name,
        desc,
        stock,
        userId,
        userName: data.name,
      };
      const res = await axios.post(
        `${server}/product/addPendingProduct`,
        formInfo
      );
      window.location.reload(true);
      toast.success("produit cree avec success");
      window.location.reload();
    } catch (err) {
      toast.error(err.response.data.message);
      // Handle error
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="Creér un produit" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Nom d'un produit"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="stock"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
          />
        </Box>
        <Box mt="20px">
          <Button type="submit" variant="contained" color="primary">
            Créer un produit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormProductStock;
