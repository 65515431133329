import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormEmployee from "scenes/formemployee";
import Popup from "components/Popup";
import {
  CheckBox,
  DeleteOutline,
  DeleteOutlined,
  HolidayVillageTwoTone,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./employe.css";
import axios from "axios";
import { server } from "server";
import { backend_url } from "server";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "@mui/material";
import DataGridEmployeToolbar from "components/DataGridEmployeToolbar";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import PopupPdf from "components/PopupPdf";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import PersonIcon from "@mui/icons-material/Person";

const Employees = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    axios.get(`${server}/employee/get-all-employees`).then((res) => {
      setEmployee(res.data.allemployees);
    });
  }, []);

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const handleDelete = (id) => {
    axios
      .delete(`${server}/employee/delete-employee/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const accountNumber1 = "702-094799-02-67";
  const accountNumber2 = "702-14741-01-91";
  const handleViewPaymentDetails = async () => {
    try {
      const response = await axios.get(`${server}/loan/payment`);
      const employeePayments = response.data.employeePayments;

      // Function to format numbers with commas
      const formatNumber = (number, locale = "en-US") => {
        return new Intl.NumberFormat(locale).format(number);
      };

      const totalPayment = employeePayments.reduce(
        (total, payment) => total + payment.payment,
        0
      );

      const doc = new jsPDF();

      const addHeader = () => {
        const pageWidth = doc.internal.pageSize.getWidth();

        // Format today's date as dd-mm-yyyy
        const today = new Date();
        const day = String(today.getDate()).padStart(2, "0");
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const year = today.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text("Bismos", pageWidth - 30, 15, { align: "right" });
        doc.setFontSize(10);
        doc.text(
          "A. Vendeur: BISMOS NIF: 400045190/RC:03436",
          pageWidth - 30,
          20,
          { align: "right" }
        );
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          pageWidth - 30,
          25,
          { align: "right" }
        );
        doc.setFontSize(16);
        const highlightText =
          "Paiement des employés de BISMOS pour le mois de______";
        const textWidth = doc.getTextWidth(highlightText);
        const x = (pageWidth - textWidth) / 2; // Center text
        doc.text(highlightText, x, 35);

        // Add the formatted date to the header
        doc.setFontSize(10);
        doc.text(`Fait le: ${formattedDate}`, pageWidth - 30, 45, {
          align: "right",
        });
      };

      const addFooter = (isLastPage) => {
        if (isLastPage) {
          const footerY = doc.internal.pageSize.height - 40; // Adjust based on your footer height
          doc.setFontSize(9);
          doc.text(
            `Veuillez débiter mon compte No ${accountNumber1}, ouvert au nom de BISMOS, d'un montant en chiffre de ${formatNumber(
              totalPayment
            )} fbu, montant en lettres: _____________________________________________________________________, pour le crédit du compte No ${accountNumber2}, ouvert au nom de l'UCODE MF pour le paiement des salaires du personnel de l'imprimerie BISMOS. Je vous prie de croire, Monsieur le Directeur, l'expression de ma considération distinguée.`,
            10,
            footerY,
            { maxWidth: 190, align: "justify" }
          );
        }
      };

      // Add logo if available
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      addHeader();

      // Table for Employee Payments
      const tableY = 50; // Adjusted to avoid overlap with header and date
      const headers = ["Nom et Prenom", "Payment (Fbu)", "Bank Account"];
      const options = {
        startY: tableY,
        head: [headers],
        body: employeePayments.map((payment) => [
          payment.name,
          formatNumber(payment.payment), // Format payment numbers
          payment.Bank_account,
        ]),
        styles: {
          fontSize: 10,
          cellPadding: 2,
          overflow: "linebreak",
          halign: "left",
        },
        columnWidth: [80, 40, 80], // Adjust column widths as needed
        margin: { bottom: 20 }, // Adjust the margin for footer space
        pageBreak: { auto: true }, // Ensure automatic page breaks
      };

      doc.autoTable(options);

      // Add note and check if pagination is needed
      const noteY = doc.autoTable.previous.finalY + 10;
      if (doc.internal.pageSize.height - noteY < 40) {
        doc.addPage();
        addHeader(); // Add header to the new page
      }
      doc.setFontSize(10);
      doc.text("Concerne : Paiement des salaires du personnel", 10, noteY);
      doc.text("Fait par: D.G, Mr Mike B. MUHIMUZI ", 10, noteY + 5);
      doc.text("À Monsieur le Directeur de l'INTERBANK", 10, noteY + 10);

      // Ensure footer is on the last page
      const isLastPage = doc.internal.getNumberOfPages() === 1;
      addFooter(isLastPage);

      setPdfDoc(doc); // Update state with generated PDF document
      setOpenPdf(true);

      // Uncomment if you want to handle the download immediately
      // handleDownloadPDF();
    } catch (err) {
      console.error("Error generating PDF:", err);
      toast.error("An error occurred while generating the PDF");
    }
  };

  const handleDownloadPDF = () => {
    if (pdfDoc) {
      pdfDoc.save("employee_payments.pdf");
    } else {
      console.error("PDF not yet generated");
      toast.warn("No PDF available to download yet.");
    }
  };

  const columns = [
    {
      field: "avatar",
      headerName: "Nom et Prénom",
      width: 200,
      renderCell: (params) => (
        <div className="userListUser">
          {/* Display profile picture if available, otherwise show default image */}
          {params.row.avatar ? (
            <img
              className="userListImg"
              src={params.row.avatar.url}
              alt={params.row.name}
            />
          ) : (
            <PersonIcon className="userListImg" />
          )}
          {params.row.name}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "role",
      headerName: "role",
      flex: 1,
    },
    {
      field: "address",
      headerName: "adresse",
      flex: 1,
    },
    {
      field: "cni",
      headerName: "Numero de CNI",
      flex: 1,
    },
    {
      field: "bank_account",
      headerName: "compte bancaire",
      flex: 1,
    },
    {
      field: "salary",
      headerName: "salaire (Fbu)",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/EmployésEdit/" + params.row.id}>
              <button className="userListEdit">Modifié</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() =>
                setUserId(params.row.id) || setOpenDelete(!openDelete)
              }
            />
          </>
        );
      },
    },
  ];

  const row = [];

  employee &&
    employee.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        cni: item.cni,
        phoneNumber: item.phoneNumber,
        role: item.role,
        address: item.address,
        bank_account: item.bank_account,
        salary: formatNumber(item.salary),
        avatar: item.avatar,
      });
    });

  return (
    <Box m="20px">
      <Header title="Les employés" subtitle="Liste des employés" />
      <br />
      <Link to="/conge">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <HolidayVillageTwoTone /> les Congés
        </Button>
      </Link>
      <br />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridEmployeToolbar }}
          componentsProps={{
            toolbar: {
              openPopup,
              setOpenPopup,
              open,
              setOpen,
              handleViewPaymentDetails,
            },
          }}
        />
      </Box>
      <Popup title="Employé" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <FormEmployee setOpenPopup={setOpenPopup} />
      </Popup>
      {/* confirmation de payment */}
      <PopupConfirm
        title="Voulez-vous effectuer le Payment "
        open={open}
        setOpen={setOpen}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen(!open) || handleViewPaymentDetails()}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen(!open)}
          >
            <DeleteOutlined />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
      {/* confirme la suppression */}
      <PopupConfirm
        title="Voulez-vous supprimer l'employee "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(userId)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <DeleteOutlined />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
      {/* telechargement d'un pdf */}
      <PopupPdf openPdf={openPdf}>
        {pdfDoc ? (
          <div>
            {/* Display the PDF content here (optional) */}
            <IconButton onClick={() => setOpenPdf(false)}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpenPdf(false)}
              >
                X
              </Button>
            </IconButton>
            <FlexBetween>
              <p>Tu peux télécharger le PDF de Payment</p>
            </FlexBetween>
            <Button
              color="secondary"
              variant="contained"
              style={{ marginBottom: "4px" }}
              onClick={handleDownloadPDF}
            >
              Download PDF
            </Button>
          </div>
        ) : (
          <p>No PDF generated yet.</p>
        )}
      </PopupPdf>
    </Box>
  );
};

export default Employees;
