import {
  Box,
  Button,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { Visibility, VisibilityOff, PersonOutline } from "@mui/icons-material";
import { toast } from "react-toastify";

const FormCreateUser = ({ openPopup, setOpenPopup }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [occupation, setOccupation] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cni, setCni] = useState("");
  const [profilepic, setProfilePic] = useState(null); // For handling file upload
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !name ||
        !email ||
        !password ||
        !occupation ||
        !role ||
        !phoneNumber ||
        !cni ||
        !profilepic
      ) {
        toast.error("Veuillez remplir tous les champs!!");
        return;
      }

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("occupation", occupation);
      formData.append("role", role);
      formData.append("phoneNumber", phoneNumber);
      formData.append("cni", cni);
      formData.append("profilepic", profilepic);

      const res = await axios.post(`${server}/user/create-user`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("User created successfully");
      window.location.replace("/admin");
    } catch (err) {
      console.log(err);
      toast.error("Error creating user");
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setProfilePic(file);
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="CREER UN UTILISATEUR" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          occupation: "",
          role: "",
          phoneNumber: "",
          cni: "",
        }}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password"} // Toggle password visibility
                label="Password"
                onBlur={handleBlur}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Occupation"
                onBlur={handleBlur}
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
              <TextField
                select
                fullWidth
                variant="filled"
                label="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="superadmin">superadmin</MenuItem>
                <MenuItem value="admin">admin</MenuItem>
                <MenuItem value="gestionnaire">gestionnaire</MenuItem>
                <MenuItem value="caissiere">caissiere</MenuItem>
                <MenuItem value="receptioniste">receptioniste</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="CNI"
                onBlur={handleBlur}
                value={cni}
                onChange={(e) => setCni(e.target.value)}
              />
              {/* File upload input */}
              <input
                type="file"
                name="profilepic"
                id="file-input"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileInputChange}
              />

              {/* Display selected image or silhouette */}
              <Avatar
                src={profilepic ? URL.createObjectURL(profilepic) : ""}
                alt="Profile Picture"
              >
                {!profilepic && <PersonOutline />}{" "}
                {/* Display silhouette if no image selected */}
              </Avatar>
            </Box>
            <Box mt="20px">
              <Button variant="contained" color="primary" type="submit">
                Créer l'utilisateur
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// Form validation schema
const checkoutSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  occupation: yup.string().required("Occupation is required"),
  role: yup.string().required("Role is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  cni: yup.string().required("CNI is required"),
});

export default FormCreateUser;
