import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import axios from "axios";
import { server } from "../../../src/server";
import FormImpression from "scenes/formImpression";
import { ArrowLeftSharp } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";

const ImpressionType = () => {
  const theme = useTheme();

  // console.log(data);

  const [impressions, setImpressions] = useState([]);

  const IdImpression = localStorage.getItem("impressionId");

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/impression/get-impressions`
        );
        const impressionsArray = response.data.impressions || []; // Access prestations array directly or use empty array if not available
        setImpressions(impressionsArray);
        // console.log(impressionsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // values to be sent to the backend
  // const [items, setItems] = useState(itemsData);

  const [openPopup, setOpenPopup] = useState(false);

  //   const { data, isLoading } = useGetTransactionsQuery({
  //     page,
  //     pageSize,
  //     sort: JSON.stringify(sort),
  //     search,
  //   });

  //delete item

  const handleDelete = async (impressionsId) => {
    try {
      if (
        window.confirm("Are you sure you want to delete this type of printing?")
      ) {
        const response = await axios.delete(
          `${server}/impression/delete-impression/${IdImpression}/${impressionsId}`
        );
        // Assuming your backend returns a success message upon deletion
        if (response.data.success) {
          // Refetch data after deletion or update UI as needed
          window.location.replace("/impression");
          // Optionally, you can navigate to a different route upon successful deletion
          // history.push("/some-route");
        } else {
          // Handle deletion failure
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "nameImpression",
      headerName: "Type d'impression",
      flex: 2,
    },
    {
      field: "price",
      headerName: "le prix",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/impression/${IdImpression}/` + params.row.id}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Link to={"/devis"}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <ArrowLeftSharp />
          </Button>
        </Link>
        <Header title="Les types d'impressions" />
      </FlexBetween>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          //   loading={isLoading || !data}
          //   getRowId={(row) => row._id}

          rows={impressions.map((impression) => ({
            id: impression._id, // Assuming _id is the unique identifier
            nameImpression: impression.nameImpression,
            price: impression.price,
          }))}
          columns={columns}
          // pageSize={8}
          //   rowCount={(data && data.total) || 0}
          //   rowsPerPageOptions={[20, 50, 100]}
          //   pagination
          //   page={page}
          //   pageSize={pageSize}
          //   paginationMode="server"
          //   sortingMode="server"
          //   onPageChange={(newPage) => setPage(newPage)}
          //   onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          //   onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Ajouter un nouveau type d'impression(comme prestation)"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormImpression setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default ImpressionType;
