import axios from "axios";
import { useState, useEffect } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ArrowLeftSharp, CheckBox } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { toast } from "react-toastify";
import Popup from "components/Popup";
import PopupConfirm from "components/PopupConfirm";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import FlexBetween from "components/FlexBetween";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import { server } from "server";

const Facture = () => {
  const theme = useTheme();
  const [pdfs, setPdfs] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [pdfData, setPdfData] = useState(null);

  const handleViewPaymentDetails = async (id) => {
    try {
      const response = await axios.get(`${server}/final-pdf/final-pdf/${id}`);
      const pdf = response.data;

      const doc = new jsPDF();
      doc.setProperties({
        title: `facture-${pdf.nomClient}`,
        subject: "Facture",
        author: "Bismos",
      });

      // Add logo if available
      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20);
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.setFontSize(10);
      doc.text("NIF: 400045190/RC:03436", 10, businessY + 5);
      doc.text("Avenue: KIGARAMA, Ngozi, N0: 21", 10, businessY + 10);
      doc.text("centre Fiscal:Ressort Nord", 10, businessY + 15);
      doc.text("Secteur d'activité: COM. General", 10, businessY + 20);
      doc.text("    ", 10, businessY + 30);

      // Invoice Details
      const invoiceY = businessY + 21;
      doc.setFontSize(12);
      doc.text("          Facture", doc.internal.pageSize.getWidth() - 70, 20);
      doc.text(`Nom de l'entreprise: ${pdf.nomClient}`, 10, invoiceY + 5);
      doc.text(`Personnne de Contact: ${pdf.personContact}`, 10, invoiceY + 10);
      doc.text(
        `Numero de contact: ${pdf.personContactNumber}`,
        10,
        invoiceY + 15
      );
      // Add checkboxes below Numero de contact
      const checkboxY = invoiceY + 20; // Adjust Y position as needed
      doc.setFontSize(10);
      doc.text("assujetti à la TVA : ", 10, checkboxY + 2);
      doc.rect(40, checkboxY - 2, 5, 5); // Checkbox 1
      doc.text("Oui", 45, checkboxY + 2);
      doc.rect(52, checkboxY - 2, 5, 5); // Checkbox 2
      doc.text("Non", 57, checkboxY + 2);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );
      doc.text(
        `No de la facture: ${pdf.invoiceNumber}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY + 5
      );
      doc.text(
        `Validité: un mois`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY + 10
      );

      // Table for Invoice Items
      const tableY = checkboxY + 20;
      const headers = [
        "Nom du produit",
        "Quantité",
        "Prix unitaire",
        "T V A par unité",
        "Prix Total (en fbu)",
      ];
      const items = pdf.items.map((item) => [
        item.item,
        item.description,
        item.prix,
        item.nombre_page,
        item.color,
      ]);
      doc.autoTable({
        startY: tableY,
        head: [headers],
        body: items,
        styles: {
          fontSize: 10,
          cellPadding: 2,
          overflow: "linebreak",
          halign: "left",
        },
        columnWidth: [80, 80, 40, 40, 40],
      });

      // Footer Text
      const footerY = doc.internal.pageSize.getHeight() - 20;
      const footerText =
        "Tel: 79 967 624 / 22303165 ||KCB:06691079043 ,Bancobu :20002-11109-06571220101-09";
      const footerText2 = "www.bismos.com || E-mail: info@bismos.com";
      doc.setFont("helvetica", "normal");
      doc.text(footerText, doc.internal.pageSize.getWidth() / 2, footerY, {
        align: "center",
      });
      doc.text(footerText2, doc.internal.pageSize.getWidth() / 2, footerY + 5, {
        align: "center",
      });

      setPdfData({
        doc: doc,
        pdf: pdf,
      });

      setOpen1(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("An error occurred while generating the PDF");
    }
  };

  const handleDownloadPDF = () => {
    if (pdfData && pdfData.doc) {
      pdfData.doc.save(
        `facture_${pdfData.pdf?.nomClient}_${pdfData.pdf?.invoiceNumber}.pdf`
      );
    } else {
      console.error("PDF not yet generated");
      toast.warn("No PDF available to download yet.");
    }
  };

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const res = await axios.get(`${server}/final-pdf/final-pdfs`);
        setPdfs(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPDFs();
  }, []);

  const columns = [
    { field: "id", headerName: "ID facture", flex: 1 },
    { field: "nomClient", headerName: "Nom du client", flex: 2 },
    { field: "url", headerName: "Nom du facture", flex: 3 },
    { field: "finalPrice", headerName: "Prix total", flex: 1 },
    { field: "userName", headerName: "Fait par", flex: 1 },
    { field: "createdAt", headerName: "Crée le", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewPaymentDetails(params.row.id)}
          >
            <img
              src={pdfPicture}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                marginRight: "10px",
                transition: "transform 0.3s",
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Link to={"/pdf"}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <ArrowLeftSharp />
          </Button>
        </Link>
        <Header title="Les factures finales" />
      </FlexBetween>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={pdfs.map((pdf) => ({
            id: pdf._id,
            nomClient: pdf.nomClient,
            url: pdf.url,
            finalPrice: pdf.finalPrice,
            userName: pdf.userName,
            createdAt: pdf.createdAt?.slice(0, 10),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      <Popup
        title="Veux-tu télécharger ce fichier?"
        openPopup={open1}
        setOpenPopup={setOpen1}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => {
              setOpen1(false);
              handleDownloadPDF();
            }}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen1(false)}
          >
            X
          </Button>
        </FlexBetween>
      </Popup>
    </Box>
  );
};

export default Facture;
