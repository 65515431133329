import { ArrowLeftSharp } from '@mui/icons-material';
import { Box, Button, Toolbar, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import Header from 'components/Header';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { server } from 'server';

const Conge = () => {
    const theme = useTheme();
    const [employee, setEmployee] = useState([]);
  
    useEffect(() => {
        // Fetch data from the API endpoint
        axios.get(`${server}/employee/api/employee`)
            .then((res) => {
                setEmployee(res.data.employeeHoliday); // Assuming the response structure contains the employee data directly
            })
            .catch((err) => {
                console.error("Error fetching employee data:", err);
            });
    }, []);

    const handleDelete = (id) => {
        axios
          .delete(`${server}/employee/delete-holiday/${id}`)
          .then((res) => {
            toast.success(res.data.message);
            window.location.reload();
          })
          .catch((err) => {
            toast.error(err);
          });
      };
  
    const columns = [
        {
            field: "createdAt",
            headerName: "Fait le ",
            flex: 1,
        },
        {
            field: "name",
            headerName: "Nom et Prenom",
            flex: 1,
        },
        {
            field: "totalHolidaysTaken",
            headerName: "les Conges Donneés",
            flex: 1,
        },
        {
            field: "remainingHolidays",
            headerName: "les congés restants",
            flex: 1,
        },
        {
            field: "status",
            headerName: "Statut",
            flex: 1,
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 100,
            renderCell: (params) => (
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginBottom: "6px" }}
                  onClick={()=>handleDelete(params.row.id)}
                >
                  Supprimer
                </Button>
            ),
          },
    ];
  
    const rows = employee.map((item) => ({
        id: item._id,
        name: item.name,
        totalHolidaysTaken: item.totalHolidaysTaken + " jours",
        remainingHolidays: item.remainingHolidays + " jours",
        createdAt: item.createdAt.slice(0, 10),
        status: item.status === 'pending' ? 'En attente' : 'Terminé',
    }));
  
    return (
        <Box m="20px">
            <Link to={"/employés"}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <ArrowLeftSharp /> retour
          </Button>
        </Link>
            <Header title="Les congés" subtitle="Liste des employés qui ont eu des conges" />
            <Box
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
}

export default Conge;
