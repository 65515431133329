import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Menu, MenuItem, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbarDails from "components/DataGridCustomToolbarDetails";
import Popup from "components/Popup";
import { Link, useParams } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormPrestation from "scenes/formPrestation";
import { useGetPrestationsQuery, useGetUserQuery } from "state/api";
import axios from "axios";
import { server } from "../../../src/server";
import FormAddCommande from "scenes/formAddCommande";
import CommandeEdit from "scenes/Commande-Edit";
import { toast } from "react-toastify";
import "./commande.css";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { ArrowLeftSharp } from "@mui/icons-material";

const CommandeDetails = () => {
  const theme = useTheme();
  const { id } = useParams();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  // console.log(data.name);

  const [prestations, setPrestations] = useState([]);
  const IdPrestation = localStorage.getItem("prestationId");
  const [nomClient, setNomClient] = useState("");
  const [contact, setContact] = useState("");
  const [selectedCommandeId, setSelectedCommandeId] = useState(null);
  const [totalPriceTotal, setTotalPriceTotal] = useState("");

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/commande/${id}`);
        const prestationsArray = response.data.prestations || []; // Access prestations array directly or use empty array if not available
        const nomClient1 = response.data.nomClient;
        const contact1 = response.data.contact;
        const total = prestationsArray.reduce(
          (sum, prestation) => sum + (prestation.price_total || 0),
          0
        );
        setTotalPriceTotal(total);
        setNomClient(nomClient1);
        setContact(contact1);
        setPrestations(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);

  //delete item
  const handleDelete = async (selectedCommandeId) => {
    try {
      if (window.confirm("veux-tu supprimer cette commande?")) {
        const response = await axios.delete(
          `${server}/commande/delete-commande/${id}/${selectedCommandeId}`
        );
        // Assuming your backend returns a success message upon deletion
        if (response.data.success) {
          // Refetch data after deletion or update UI as needed
          window.location.reload(true);
        } else {
          // Handle deletion failure
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${server}/generate-pdf-commande`,
        {
          nomClient,
          contact,
          prestations,
          userName: data.name,
          totalPriceTotal,
        },
        { withCredentials: true }
      );
      toast.success("PDF commande generated successfully!");
      setTimeout(() => {
        window.location.replace("/pdf-commande");
      }, 2000);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };

  const handleUpdateStatus = async (prestationId, status) => {
    try {
      const response = await axios.put(
        `${server}/commande/update-commande-status/${id}/${prestationId}`,
        { status }
      );

      if (response.data.success) {
        // Find and update the prestation in state
        setPrestations((prevPrestations) =>
          prevPrestations.map((prestation) =>
            prestation._id === prestationId
              ? { ...prestation, status }
              : prestation
          )
        );
        toast.success("Status du produit a été mise a jour");
      } else {
        // Handle status update failure
      }
    } catch (error) {
      toast.error("Une erreur s'est produit");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };
  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    production: theme.palette.info.main,
    pending: theme.palette.neutral.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: "Livré",
    not_delivered: "Non livré",
    half_delivered: "Livré avec dette",
    production: "En production",
    pending: "En attente de récuperation",
    not_production: "Pas encore en production",
  };

  const columns = [
    {
      field: "commande",
      headerName: "commande",
      flex: 2,
    },
    {
      field: "qty",
      headerName: "La quantité",
      flex: 1,
    },
    {
      field: "price_unitaire",
      headerName: "prix unitaire",
      flex: 1,
    },
    {
      field: "price_total",
      headerName: "prix total",
      flex: 1,
    },
    {
      field: "avance",
      headerName: "Paiement",
      flex: 1,
    },
    {
      field: "reste",
      headerName: "reste",
      flex: 1,
    },
    {
      field: "desc",
      headerName: "desc",
      flex: 1,
    },
    {
      field: "relation",
      headerName: "rendez-vous",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: statusColors[params.row.status] }}
              onClick={(event) => handleClick(event, params.row.id)}
              sx={{ width: "200px" }}
            >
              {statusLabels[params.row.status]}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRowId === params.row.id}
              onClose={handleClose}
            >
              {Object.keys(statusLabels).map((status) => (
                <MenuItem
                  key={status}
                  onClick={() => handleUpdateStatus(params.row.id, status)}
                >
                  {statusLabels[status]}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Fait le",
      flex: 1.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {/* <div>
              <button className="userListEdit" onClick={handleSubmit}>
                Créer la facture
              </button>
            </div> */}

            <button
              className="userListEdit"
              onClick={() => {
                setSelectedCommandeId(params.row.id);
                setOpenPopup1(true);
              }}
            >
              Editer
            </button>

            {/* <Link to={"/commande-payment/" + params.row.id}>
              <button className="userListEdit">Paiement</button>
            </Link> */}

            <DeleteOutline
              className="userListDelete"
              onClick={() => {
                setSelectedCommandeId(params.row.id);
                handleDelete(params.row.id);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={`détails des commandes de: ${nomClient} `}
        subtitle={`le numero de contact de:  ${nomClient}: ${contact} `}
      />
      <br />
      <Link to="/comptabilité">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <Grid style={{ display: "flex", marginTop: "10px" }}>
        <Button
          color="secondary"
          variant="contained"
          style={{
            marginBottom: "6px",
            marginRight: "30px",
            fontWeight: "bold",
          }}
          endIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          Creer la facture
        </Button>

        <Button
          color="secondary"
          variant="contained"
          style={{
            marginBottom: "6px",
            marginRight: "30px",
            fontWeight: "bold",
          }}
          endIcon={<SendIcon />}
        >
          {" "}
          <Link
            to="/pdf-commande"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Voir les factures des commandes
          </Link>
        </Button>
      </Grid>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={prestations.map((prestation) => ({
            id: prestation._id, // Assuming _id is the unique identifier
            nomClient: prestation.nomClient,
            contact: prestation.contact,
            commande: prestation.commande,
            status: prestation.status,
            price_unitaire: formatNumber(prestation.price_unitaire),
            price_total: formatNumber(prestation.price_total),
            qty: prestation.qty,
            avance: formatNumber(prestation.avance),
            reste: formatNumber(prestation.reste),
            desc: prestation.desc,
            relation: prestation.relation,
            createdAt: prestation.createdAt?.slice(0, 16),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>

      <Popup
        title="Ajouter une autre commande du client"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormAddCommande setOpenPopup={setOpenPopup} id={id} />
      </Popup>
      <Popup
        title="METTRE A JOUR LA COMMANDE"
        openPopup={openPopup1}
        setOpenPopup={setOpenPopup1}
      >
        <CommandeEdit
          setOpenPopup1={setOpenPopup1}
          id={id}
          commandeId={selectedCommandeId}
        />
      </Popup>
    </Box>
  );
};

export default CommandeDetails;
