import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Employees from "scenes/employees";
import Sells from "scenes/vente";
import Login from "components/Login";
import Overview from "scenes/overview";
import Daily from "scenes/daily";

import Breakdown from "scenes/breakdown";
import ProtectedRoute from "routes/ProtectedRoute";
import LoginRoute from "routes/LoginRoute";
import FormSample from "./components/FormSample";
import Devis from "scenes/devis";
import Prices from "scenes/prices";
import PriceEdit from "scenes/priceEdit";
import Papiers from "scenes/paper";
import Pdf from "scenes/Pdf";
import PaperEdit from "scenes/paperEdit";
import Team from "scenes/admin";
import EditTeamMember from "scenes/EditTeamMember";
import ImpressionType from "scenes/impression";
import ImpressionEdit from "scenes/ImpressionEdit";
import PaperCover from "scenes/paperCover";
import PaperCoverEdit from "scenes/paperCoverEdit";
import EmployeeEdit from "scenes/employeeEdit";
import ProductEdit from "scenes/productEdit";
import Loan from "scenes/loan";
import PdfEdit from "scenes/pdfEdit";
import Facture from "scenes/facture";
import Avertissement from "scenes/avertissement";
import { useGetUserQuery } from "state/api";
import AccesDenied from "components/AccesDenied";
import Settings from "scenes/settings";
import Transactions from "scenes/transactions";
import Depenses from "scenes/depenses";
import DepenseEdit from "scenes/depenseEdit";
import Stocks from "scenes/stock";
import Print from "scenes/print";
import History from "scenes/history";
import Commande from "scenes/commandes";
import CommandeDetails from "scenes/commandeEdit";
import CommandeEdit from "scenes/Commande-Edit";
import PdfCommande from "scenes/pdf-Commande";
import AccesDeniedDashboard from "components/AccesDeniedDashboard";
import EntryPage from "components/EntryPage";
import Retrait from "scenes/retrait";
import RetraitDetails from "scenes/retraitDetails";
import DailyCommande from "scenes/DailyCommande";
import MonthlyCommande from "scenes/monthlyCommande";
import HistoryRetrait from "scenes/historyRetrait";
import PendingProduct from "scenes/pendigProduct";
import PendingProductEdit from "scenes/pendingProductEdit";
import FAQ from "scenes/faq";
import CommandeDetailsAllUsers from "scenes/commandeDetailsAll";
import CommandePayment from "scenes/commandePayement";
import ExternalRedirect from "components/ExternalRederict";
import Conge from "scenes/conge";
import StockRecord from "scenes/StockRecord";
import ProductStockEdit from "scenes/productStockEdit";
import Depot from "scenes/depot";
import ProductionPrestations from "scenes/StatusInProduct";
import PendingPrestations from "scenes/StatusPending";
import NotProductionPrestations from "scenes/StatusNotProduction";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  // console.log(data.role);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              element={
                <ProtectedRoute>
                  <Layout />
                </ProtectedRoute>
              }
            >
              {/* {Modules} */}
              <Route path="/" element={<Navigate to="/acceuil" replace />} />
              <Route
                path="/acceuil"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <Dashboard />
                    ) : (
                      <AccesDeniedDashboard />
                    )}
                  </ProtectedRoute>
                }
              />
              {/* {route des produits ou achats} */}
              <Route
                path="/achats"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ||
                    data?.role === "admin" ? (
                      <Products />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/avertissement"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <Avertissement />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/employés"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <Employees />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/ventes"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <Sells />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/commandes"
                element={
                  <ProtectedRoute>
                    <Commande />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/pdf-commande"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ||
                    data?.role === "receptioniste" ? (
                      <PdfCommande />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/commande-details/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <CommandeDetails />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/commande-details-all/:id"
                element={
                  <ProtectedRoute>
                    <CommandeDetailsAllUsers />
                  </ProtectedRoute>
                }
              />
               <Route path="/prestations/not-production" 
               element={
                <ProtectedRoute>
                  <NotProductionPrestations />
                </ProtectedRoute>
              }
             />
              <Route path="/prestations/production" 
              element={
                <ProtectedRoute>
                  <ProductionPrestations />
                </ProtectedRoute>
              } />
                <Route path="/prestations/pending" 
                element={
                  <ProtectedRoute>
                    <PendingPrestations />
                  </ProtectedRoute>
                }/>

              <Route
                path="/commande-payment/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <CommandePayment />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/commande-edit/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <CommandeEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/depenses"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <Depenses />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/depense/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <DepenseEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/devis"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ||
                    data?.role === "gestionnaire" ||
                    data?.role === "receptionniste" ? (
                      <Devis />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/prix"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? <Prices /> : <AccesDenied />}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/prix/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <PriceEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              {/* {papier interieur} */}
              <Route
                path="/papier"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <Papiers />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/papier/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <PaperEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              {/* {les papiers couvertures} */}

              <Route
                path="/papier-couverture"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <PaperCover />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/papier-couverture/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <PaperCoverEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/pdf"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "receptioniste" ||
                    data?.role === "caissiere" ? (
                      <Pdf />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pdf/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ? (
                      <PdfEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/facture"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ? (
                      <Facture />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/impression"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <ImpressionType />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/impression/:id/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <ImpressionEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              {/* {statistiques} */}
              <Route
                path="/vue-rapide"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <Overview />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/Comptabilité"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <DailyCommande />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mensuel"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <MonthlyCommande />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/graphe"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <Breakdown />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? <Team /> : <AccesDenied />}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/team/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <EditTeamMember />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              {/* {diego's rotes} */}

              <Route
                path="/EmployésEdit/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <EmployeeEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pendingproduct"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ||
                    data?.role === "gestionnaire" ? (
                      <PendingProduct />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/PendingProductEdit/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ? (
                      <PendingProductEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/HistoryRetrait"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "admin" ||
                    data?.role === "caissiere" ? (
                      <HistoryRetrait />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/productEdit/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ? (
                      <ProductEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/crédits"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <Loan />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/conge"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <Conge />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ProductPriceEdit/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <ProductStockEdit />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/stocks"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "gestionnaire" ||
                    data?.role === "receptioniste" ||
                    data?.role === "caissiere" ? (
                      <Retrait />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/transaction"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <Transactions />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/retraitDetails/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <RetraitDetails />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/recordstock"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "gestionnaire" ? (
                      <StockRecord />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/print/:id"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <Print />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />

              <Route
                path="/history"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" || data?.role === "admin" ? (
                      <History />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
              <Route path="/faq" element={<FAQ />} />
              <Route
                path="/Bureautique"
                element={
                  <ExternalRedirect url="https://bureautique.bismos.com/" />
                }
              />
              <Route
                path="/Dépots-banque"
                element={
                  <ProtectedRoute>
                    {data?.role === "superadmin" ||
                    data?.role === "caissiere" ? (
                      <Depot />
                    ) : (
                      <AccesDenied />
                    )}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/login"
              element={
                <LoginRoute>
                  <Login />
                </LoginRoute>
              }
            />
            <Route
              path="/entry-page"
              element={
                <LoginRoute>
                  <EntryPage />
                </LoginRoute>
              }
            />
            {/* <Route path="/yearly" element={<YearlyCommande />} /> */}
          </Routes>
        </ThemeProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
