import React from "react";
import {
  AddBox,
  MonetizationOn,
  HistoryOutlined,
  CreditCardSharp,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { Link } from "react-router-dom";

const DataGridEmployeToolbar = ({ setOpenPopup, openPopup, open, setOpen }) => {
  return (
    <FlexBetween>
      <GridToolbar />
      <Button
        color="secondary"
        variant="contained"
        style={{ marginBottom: "6px" }}
        onClick={() => setOpen(!open)}
      >
        <MonetizationOn /> paiement
      </Button>
      <Link to="/crédits">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={() => setOpen(!open)}
        >
          <CreditCardSharp /> Crédits
        </Button>
      </Link>
      <Link to="/history">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <HistoryOutlined /> Historique
        </Button>
      </Link>
      <Button
        color="secondary"
        variant="contained"
        style={{ marginBottom: "6px" }}
        onClick={() => setOpenPopup(!openPopup)}
      >
        <AddBox /> Ajouter
      </Button>
    </FlexBetween>
  );
};

export default DataGridEmployeToolbar;
