import { Box, Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { server } from "../../../src/server";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import { toast } from "react-toastify";

const FormMainCommand = ({ openPopup, setOpenPopup }) => {
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [nomClient, setNomClient] = useState("");
  const [contact, setContact] = useState("");
  const [nif, setNif] = useState("");
  const [adresse, setAdresse] = useState("");
  const [assujettiTVA, setAssujettiTVA] = useState(false);
  const [personContactName, setPersonContactName] = useState("");
  const [personContactPhone, setPersonContactPhone] = useState("");
  const [selectedDateDailyOthers, setSelectedDateDailyOthers] = useState("");
  const [prestations, setPrestations] = useState({
    commande: "",
    qty: 0,
    price_unitaire: 0,
    price_total: 0,
    avance: 0,
    reste: 0,
    desc: " ",
    relation: selectedDateDailyOthers,
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // To manage submission state

  useEffect(() => {
    // Calculate price_total whenever qty or price_unitaire changes
    setPrestations((prevState) => ({
      ...prevState,
      price_total: Number(prestations.qty) * Number(prestations.price_unitaire),
    }));
  }, [prestations.qty, prestations.price_unitaire]);

  useEffect(() => {
    // Calculate reste whenever price_total or avance changes
    setPrestations((prevState) => ({
      ...prevState,
      reste: Number(prestations.price_total) - Number(prestations.avance),
    }));
  }, [prestations.price_total, prestations.avance]);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true); // Disable the button after the first click
    try {
      const res = await axios.post(`${server}/commande/create-commande/`, {
        userId,
        userName: data.name,
        nomClient,
        contact,
        nif,
        adresse,
        assujettiTVA,
        personContactName,
        personContactPhone,
        prestations,
      });
      window.location.reload(true);
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message) {
        toast.error(error.response.data.message); // Show backend error message
      } else {
        toast.error("Une erreur est survenue lors de la création de la commande car ce nom de societe existe.");
      }
    } finally {
      setIsSubmitting(false); // Re-enable the button after submission
    }
  };

  return (
    <Box m="20px">
      <FlexBetween>
        <Header title="CRÉATION D'UNE COMMANDE" />
        <Button color="secondary" variant="contained" onClick={() => setOpenPopup(false)}>
          X
        </Button>
      </FlexBetween>

      <Formik>
        {({ handleBlur }) => (
          <form>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Company Details */}
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Nom de la société"
                onBlur={handleBlur}
                value={nomClient}
                onChange={(e) => setNomClient(e.target.value)}
                name="nomClient"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Numéro de la société"
                onBlur={handleBlur}
                onChange={(e) => setContact(e.target.value)}
                value={contact}
                name="contact"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="NIF"
                onBlur={handleBlur}
                value={nif}
                onChange={(e) => setNif(e.target.value)}
                name="nif"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Adresse de la société"
                onBlur={handleBlur}
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                name="adresse"
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 2" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assujettiTVA}
                      onChange={(e) => setAssujettiTVA(e.target.checked)}
                      name="assujettiTVA"
                      color="primary"
                    />
                  }
                  label="La société est assujettie pour la TVA"
                />
              </Box>
              {/* Contact Person Details */}
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Personne de contact"
                onBlur={handleBlur}
                value={personContactName}
                onChange={(e) => setPersonContactName(e.target.value)}
                name="personContactName"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Téléphone du contact"
                onBlur={handleBlur}
                value={personContactPhone}
                onChange={(e) => setPersonContactPhone(e.target.value)}
                name="personContactPhone"
                sx={{ gridColumn: "span 2" }}
              />
              {/* Prestations Details */}
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="La commande"
                onBlur={handleBlur}
                onChange={(e) => setPrestations({ ...prestations, commande: e.target.value })}
                name="commande"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="La quantité"
                onBlur={handleBlur}
                onChange={(e) => setPrestations({ ...prestations, qty: parseInt(e.target.value) })}
                name="qty"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Prix unitaire"
                onBlur={handleBlur}
                onChange={(e) => setPrestations({ ...prestations, price_unitaire: parseInt(e.target.value) })}
                name="price_unitaire"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                readOnly
                type="number"
                label={`Prix total (PT) : ${prestations.price_total}`}
                value={prestations.price_total}
                name="price_total"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                readOnly
                type="number"
                label={`Le reste : ${prestations.reste}`}
                value={prestations.reste}
                name="reste"
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="string"
                label="Observation"
                onBlur={handleBlur}
                onChange={(e) => setPrestations({ ...prestations, desc: e.target.value })}
                name="desc"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                id="date"
                label="Sélectionner la date du rendez-vous"
                type="date"
                value={prestations.relation}
                onChange={(e) => setPrestations({ ...prestations, relation: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting} // Disable button during submission
              >
                {isSubmitting ? "Création en cours..." : "Créer une commande"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FormMainCommand;
