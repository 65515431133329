import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { backend_url, server } from "../../server";
import axios from "axios";
import {
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import Popup from "components/Popup";
import FormPassword from "scenes/formPassword";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import "./user.css"; // Ensure to update this file for any additional styles

export default function Settings() {
  const [openPopup, setOpenPopup] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    profilepic: "",
    phoneNumber: "",
    cni: "",
    occupation: "",
    role: "",
  });

  useEffect(() => {
    const fetchPrestation = async () => {
      try {
        const Id = await localStorage.getItem("secretKeyesforzado7");
        const response = await axios.get(`${server}/user/${Id}`);
        setUser(response.data);
        setFormData({
          name: response.data.name,
          email: response.data.email,
          profilepic: response.data.profilepic,
          phoneNumber: response.data.phoneNumber,
          cni: response.data.cni,
          occupation: response.data.occupation,
          role: response.data.role,
        });
      } catch (error) {
        setError("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchPrestation();
  }, []);

  const clearAllTokens = async () => {
    try {
      localStorage.removeItem("secretKeyesforzado7");
      toast.success("Logged out successfully");
      window.location.reload(true);
    } catch (error) {
      console.log("Error clearing all tokens: ", error);
      toast.error("An error occurred");
    }
  };

  //DESACTIVITE TO LOGOUT IN 10 MINUTES

  useEffect(() => {
    let inactivityTimeout;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(clearAllTokens, 1 * 60 * 1000); // 10 minutes
    };

    const setupInactivityTimer = () => {
      // Reset inactivity timeout on any of these events
      window.addEventListener("mousemove", resetInactivityTimeout);
      window.addEventListener("keypress", resetInactivityTimeout);
      window.addEventListener("click", resetInactivityTimeout);
      window.addEventListener("scroll", resetInactivityTimeout);
      window.addEventListener("touchstart", resetInactivityTimeout);

      // Initial setup of inactivity timeout
      resetInactivityTimeout();
    };

    // Setup the inactivity timer when the component mounts
    setupInactivityTimer();

    // Cleanup function to remove event listeners and clear timeout when the component unmounts
    return () => {
      clearTimeout(inactivityTimeout);
      window.removeEventListener("mousemove", resetInactivityTimeout);
      window.removeEventListener("keypress", resetInactivityTimeout);
      window.removeEventListener("click", resetInactivityTimeout);
      window.removeEventListener("scroll", resetInactivityTimeout);
      window.removeEventListener("touchstart", resetInactivityTimeout);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: isMobile ? 2 : 5,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2, textAlign: "center" }}>
        Editer l'Utilisateur: <strong>{user?.name}</strong>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup(!openPopup)}
          sx={{ marginBottom: isMobile ? 1 : 0 }}
        >
          Changer le mot de passe
        </Button>
        <Button color="secondary" variant="contained" onClick={clearAllTokens}>
          Se déconnecter
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 4,
          justifyContent: "center",
          marginTop: 4,
          width: "100%",
        }}
      >
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <img
              src={`${backend_url}/${user.profilepic}`}
              alt="Profile"
              className="userShowImg"
              style={{
                width: isMobile ? "150px" : "200px",
                height: isMobile ? "150px" : "200px",
                borderRadius: "10px",
              }}
            />
            <Typography variant="h5">{user?.name}</Typography>
            <Typography variant="body1">{user?.occupation}</Typography>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Typography variant="h6">Les details de l'utilisateur</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PermIdentity />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarToday />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user.occupation}
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Contact de l'utilisateur
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PhoneAndroid />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user?.phoneNumber}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <MailOutline />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {user?.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocationSearching />
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  NGOZI | BURUNDI
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 2 }}>
          <Typography
            variant="h6"
            sx={{ marginBottom: 2, textAlign: "center" }}
          >
            Editer
          </Typography>
          <form
            className="userUpdateForm"
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <TextField
              fullWidth
              variant="filled"
              label="Nom complet"
              readOnly
              value={user.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <TextField
              fullWidth
              variant="filled"
              label="Occupation"
              readOnly
              value={user.occupation}
              onChange={(e) =>
                setFormData({ ...formData, occupation: e.target.value })
              }
            />
            <TextField
              fullWidth
              variant="filled"
              label="Role"
              readOnly
              value={user.role}
              onChange={(e) =>
                setFormData({ ...formData, role: e.target.value })
              }
            />
            <TextField
              fullWidth
              variant="filled"
              label="E-mail"
              readOnly
              value={user.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <TextField
              fullWidth
              variant="filled"
              label="Numero de téléphone"
              readOnly
              value={user.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
            />
            <TextField
              fullWidth
              variant="filled"
              label="CNI ou ID"
              readOnly
              value={user?.cni}
              onChange={(e) =>
                setFormData({ ...formData, cni: e.target.value })
              }
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <img
                src={`${backend_url}/${user.profilepic}`}
                alt="Profile"
                className="userUpdateImg"
                style={{
                  width: isMobile ? "150px" : "200px",
                  height: isMobile ? "150px" : "200px",
                  borderRadius: "10px",
                }}
              />
              <label htmlFor="file">
                <Publish className="userUpdateIcon" />
              </label>
              <input type="file" id="file" style={{ display: "none" }} />
              <label htmlFor="">Only the Admin can change user info</label>
            </Box>
          </form>
        </Box>
      </Box>

      <Popup
        title="Changer le mot de passe"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormPassword setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
}
