import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { Box, Button, Paper, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { toast } from "react-toastify";

const PaperEdit = () => {
  const { id } = useParams();
  const [prestation, setPrestation] = useState(null);
  const [papier, setPapier] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [formData, setFormData] = useState({
    namePaper: "",
    price: 0,
  });

  useEffect(() => {
    const fetchPapier = async () => {
      const IdPapier = localStorage.getItem("papierId");
      try {
        const response = await axios.get(
          `${server}/papier/get-papier/${IdPapier}/${id}`
        );
        setPapier(response.data.papier);
        // Populate formData with initial values from prestation
        setFormData({
          namePaper: response.data.papier.namePaper,
          price: response.data.papier.price,
        });
      } catch (error) {
        setError("Error fetching paper");
      } finally {
        setLoading(false);
      }
    };

    fetchPapier();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const IdPapier = localStorage.getItem("papierId");
      const response = await axios.put(
        `${server}/papier/update-papier/${IdPapier}/${id}`,
        formData
      );
      toast.success("Paper updated successful");
      setTimeout(() => {
        window.location.replace("/papier");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {papier ? (
        <div>
          <Box m="20px">
            <FlexBetween>
              <Header title="LES DETAILS DU PAPIER INTERIEUR" />
            </FlexBetween>

            <Formik>
              {({ values, errors, touched, handleBlur, handleChange }) => (
                <form onSubmit={handleUpdate}>
                  <Box
                    display="grid"
                    marginTop="20px"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="string"
                      label={`Nom : ${papier.namePaper} `}
                      value={formData.namePaper}
                      onChange={(e) =>
                        setFormData({ ...formData, namePaper: e.target.value })
                      }
                      name="Nom"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label={`interieur : ${papier.price} `}
                      value={formData.price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          price: parseInt(e.target.value),
                        })
                      }
                      name="interieur"
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button color="secondary" variant="contained" type="submit">
                      Mettre à jour ce type de papier
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </div>
      ) : (
        <div>No paper found</div>
      )}
    </div>
  );
};

export default PaperEdit;
