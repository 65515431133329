import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormPrestation from "scenes/formPrestation";
import { useGetPrestationsQuery, useGetUserQuery } from "state/api";
import axios from "axios";
import { server } from "../../../src/server";
import "./commande.css";
import DataGridCustomToolbarCommande from "components/DataGridCustomToolBarCommande";
import FormMainCommand from "scenes/formMainCommand";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import { Toast } from "react-bootstrap";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";

const Commande = () => {
  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  const fetchCommandesByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/commandes-by-date/${date}`
      );
      setPrestations(response.data.commandes);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponible");
      setError("Ces données ne sont pas disponible");
      setLoading(false);
    }
  };
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    fetchCommandesByDate(date);
  };

  // const handleDownloadPDF = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${server}/commande/generate-pdf/${selectedDate}`,
  //       { responseType: "blob" } // Important to handle binary data
  //     );
  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     FileSaver.saveAs(blob, `commandes_${selectedDate}.pdf`);
  //     setLoading(false);
  //   } catch (error) {
  //     toast.error(
  //       "Il y a une erreur de génération de pdf des données demandes"
  //     );
  //     // setError("Failed to generate PDF. Please try again.");
  //     setLoading(false);
  //   }
  // };

  const [prestations, setPrestations] = useState([]);
  const IdPrestation = localStorage.getItem("prestationId");

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/all-commandes`);
        const prestationsArray = response.data || []; // Access prestations array directly or use empty array if not available
        setPrestations(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [openPopup, setOpenPopup] = useState(false);

  const handleUpdateStatus = async (id, status) => {
    try {
      const response = await axios.put(
        `${server}/commande/update-commande-status/${id}`,
        { status }
      );

      if (response.data.success) {
        toast.success("Status du produit a été mise a jour");
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      } else {
        // Handle status update failure
      }
    } catch (error) {
      toast.error("Une erreur s'est produit");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    not_paid_delivered: theme.palette.grey[500],
    production: theme.palette.info.main,
    pending: theme.palette.secondary.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: "Livré",
    not_delivered: "Non livré",
    half_delivered: "Livré avec dette",
    not_paid_delivered: "Livré sans paiement",
    production: "En production",
    pending: "En attente de récuperation",
    not_production: "Pas encore en production",
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer cette commande?")) {
        const response = await axios.delete(
          `${server}/commande/delete-commande/${id}`
        );
        // Assuming your backend returns a success message upon deletion
        if (response) {
          // Refetch data after deletion or update UI as needed
          toast.success("commande deleted successfully");

          window.location.reload(true);

          // Optionally, you can navigate to a different route upon successful deletion
          // history.push("/some-route");
        } else {
          // Handle deletion failure
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("user not found");
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Fait le",
      flex: 2,
    },
    {
      field: "nomClient",
      headerName: "nomClient",
      flex: 2,
    },
    {
      field: "contact",
      headerName: "contact",
      flex: 1,
    },
    // {
    //   field: "qty",
    //   headerName: "La quantité",
    //   flex: 1,
    // },
    // {
    //   field: "price_unitaire",
    //   headerName: "prix unitaire",
    //   flex: 1,
    // },
    // {
    //   field: "price_total",
    //   headerName: "prix total",
    //   flex: 1,
    // },
    // {
    //   field: "avance",
    //   headerName: "avance",
    //   flex: 1,
    // },
    // {
    //   field: "reste",
    //   headerName: "reste",
    //   flex: 1,
    // },
    {
      field: "userName",
      headerName: "Crée par",
      flex: 2,
    },
    // {
    //   field: "status",
    //   headerName: "Statut",
    //   flex: 3,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Button
    //           variant="contained"
    //           style={{ backgroundColor: statusColors[params.row.status] }}
    //           onClick={(event) => handleClick(event, params.row.id)}
    //           sx={{ width: "200px" }}
    //         >
    //           {statusLabels[params.row.status]}
    //         </Button>
    //         <Menu
    //           anchorEl={anchorEl}
    //           open={Boolean(anchorEl) && selectedRowId === params.row.id}
    //           onClose={handleClose}
    //         >
    //           {Object.keys(statusLabels).map((status) => (
    //             <MenuItem
    //               key={status}
    //               onClick={() => handleUpdateStatus(params.row.id, status)}
    //             >
    //               {statusLabels[status]}
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/commande-details-all/" + params.row.id}>
              <button className="userListEdit">Détails</button>
            </Link>
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="COMMANDES" subtitle="Liste des Commandes" />
      <FlexBetween>
         {/* Navigation Buttons */}
       <Link to="/prestations/not-production"style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary">
          Commandes Pas en Production
        </Button>
      </Link>
      <Link to="/prestations/production" >
        <Button variant="contained" color="primary">
          Commandes en Production
        </Button>
      </Link>
      <Link to="/prestations/pending" >
        <Button variant="contained" color="primary">
          Commandes en Attente
        </Button>
      </Link>
      
        <TextField
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          sx={{ marginRight: 2 }}
        />
        
      </FlexBetween>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={prestations.map((prestation) => {
            const totalQty = prestation.prestations.reduce(
              (sum, item) => sum + (item.qty || 0),
              0
            );
            const totalPriceUnitaire = prestation.prestations.reduce(
              (sum, item) => sum + (item.price_unitaire || 0),
              0
            );
            const totalPriceTotal = prestation.prestations.reduce(
              (sum, item) => sum + (item.price_total || 0),
              0
            );
            const totalAvance = prestation.prestations.reduce(
              (sum, item) => sum + (item.avance || 0),
              0
            );
            const totalReste = prestation.prestations.reduce(
              (sum, item) => sum + (item.reste || 0),
              0
            );

            return {
              id: prestation._id, // Assuming _id is the unique identifier
              nomClient: prestation.nomClient,
              userName: prestation.userName,
              status: prestation.status,
              contact: prestation.contact,
              commande: prestation.prestations[0]?.commande || "", // Accessing nested field
              qty: totalQty,
              price_unitaire: formatNumber(totalPriceUnitaire),
              price_total: formatNumber(totalPriceTotal),
              avance: formatNumber(totalAvance),
              reste: formatNumber(totalReste),
              desc: prestation.prestations[0]?.desc || "", // Accessing nested field
              createdAt: prestation.createdAt.slice(0, 10),
            };
          })}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbarCommande }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Créer une nouvelle commande"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormMainCommand setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default Commande;
