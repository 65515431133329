import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";

const Print = () => {
  const { id } = useParams(); // Extract ID from URL
  const [paymentDetails, setPaymentDetails] = useState(null); // Initialize with null
  const pdfRef = useRef();

  // Fetch payment details based on ID using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/loan/get-payment/${id}`);
        setPaymentDetails(response.data.paymentHistory); // Assuming response structure
      } catch (error) {
        console.error("Error fetching payment details:", error);
        // Handle errors (e.g., display error message)
      }
    };

    fetchData();
  }, [id]);

  const handleDownloadPDF = () => {
    const input = pdfRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save("Historique_de_paiement.pdf");
    });
  };

  if (!paymentDetails) {
    return <p>Loading payment details...</p>; // Display loading indicator
  }

  return (
    <div className="invoice-wrapper" id="print-area">
      <div className="invoice" ref={pdfRef}>
        <div className="invoice-container">
          <div className="invoice-head">
            <div className="invoice-head-top">
              <div className="invoice-head-top-left text-start">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="invoice-head-top-right text-end">
                <h3>Historique de paiement</h3>
              </div>
            </div>
            <div className="hr"></div>
            <div className="invoice-head-middle">
              <div className="invoice-head-middle-left text-start">
                <p>
                  <span className="text-bold">Date</span>:{" "}
                  {paymentDetails.createdAt?.slice(0, 10)}
                </p>
              </div>
            </div>
            <div className="hr"></div>
            <div className="invoice-head-bottom">
              <div className="invoice-head-bottom-left">
                <ul>
                  <li className="text-bold">Bismos High Tech:</li>
                  <li>NIF: 400045190/RC:03436</li>
                  <li>Avenue: KIGARAMA, N0: 21</li>
                  <li>centre Fiscal:Ressort Nord </li>
                  <li>Secteur d'activité: COM. General Forme Juridique: Personne Morale</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="overflow-view">
            <div className="invoice-body">
              <table>
                <thead>
                  <tr>
                    <td className="text-bold">Nom et Prenom</td>
                    <td className="text-bold">Paiement</td>
                    <td className="text-bold">Crédité</td>
                    <td className="text-bold">Crédit Restant</td>
                    <td className="text-bold">Mois à payer</td>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails.employeePayments &&
                    paymentDetails.employeePayments.map((lineItem, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 pl-0 color-black">
                          {lineItem.name}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.payment}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.hasLoan ? "Oui" : "Non"}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right color-black">
                          {lineItem.remainedCredits}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 text-right color-black">
                          {lineItem.remainedMonths}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="invoice-foot text-center">
            <p>
              <span className="text-bold text-end">NOTE:&nbsp;</span>This is
              computer generated receipt and does not require physical
              signature.
            </p>
          </div>
        </div>
      </div>
      <div className="invoice-btns">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={handleDownloadPDF} // Corrected onClick handler
        >
          Télécharger
        </Button>
      </div>
    </div>
  );
};

export default Print;
