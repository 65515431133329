import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import Popup from "components/Popup";
import { Link, useParams } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import FormPrestation from "scenes/formPrestation";
import axios from "axios";
import { server } from "../../../src/server";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";
import FormAddComponentPayment from "scenes/formAddCommandePayement";
import { ArrowLeftSharp } from "@mui/icons-material";

const CommandePayement = () => {
  const theme = useTheme();
  const { id } = useParams();

  const [prestations, setPrestations] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${server}/payment-commande/payment-history-all/${id}`
        );
        const prestationsArray = response.data.paymentHistory || [];
        setPrestations(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleDelete = async (paymentCommandeId) => {
    try {
      if (window.confirm("Veux-tu supprimer cette paiement?")) {
        const response = await axios.delete(
          `${server}/payment-commande/delete-payment-history-all/${paymentCommandeId}`
        );
        if (response.status === 200) {
          setPrestations(
            prestations.filter((item) => item._id !== paymentCommandeId)
          );
          toast.success("Paiement supprimé avec succès.");
        } else {
          toast.error("Échec de la suppression du paiement.");
        }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Erreur de suppression du paiement."
      );
      console.error("Error deleting payment:", error);
    }
  };

  const columns = [
    {
      field: "nomClient",
      headerName: "Nom du client",
      flex: 2,
    },
    {
      field: "contact",
      headerName: "Numero",
      flex: 2,
    },
    {
      field: "paidAmount",
      headerName: "La somme payée",
      flex: 2,
    },
    {
      field: "paymentMethod",
      headerName: "Methode de paiement",
      flex: 2,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 2,
    },
    {
      field: "date",
      headerName: "Payée le",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <br />
      <Link to={"/comptabilité"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <br />
      <FlexBetween>
        <Header title="Les paiements de cette commande" />
      </FlexBetween>

      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={prestations.map((prestation) => ({
            id: prestation._id, // Assuming _id is the unique identifier
            nomClient: prestation.nomClient,
            userName: prestation.userName,
            contact: prestation.contact,
            paymentMethod: prestation.transactions[0]?.paymentMethod,
            paidAmount: prestation.transactions[0]?.paidAmount,
            date: prestation.transactions[0]?.date?.slice(0, 16),
          }))}
          columns={columns}
          pageSize={8}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Ajouter un autre paiement"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormAddComponentPayment setOpenPopup={setOpenPopup} id={id} />
      </Popup>
    </Box>
  );
};

export default CommandePayement;
