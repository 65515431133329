import { Box, Button, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import FlexBetween from "components/FlexBetween";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { server } from "server";
import { toast } from "react-toastify";

const CommandeEdit = ({ setOpenPopup1, id, commandeId }) => {
  const userId = useSelector((state) => state.global.userId);
  //   console.log(commandeId);
  const [selectedDateDailyOthers, setSelectedDateDailyOthers] = useState("");
  const [prestations, setPrestations] = useState({
    commande: "",
    qty: 0,
    price_unitaire: 0,
    price_total: 0,
    avance: 0,
    reste: 0,
    desc: "",
    relation: selectedDateDailyOthers,
  });

  const handleDateChangeDailyOthers = (event) => {
    const date = event.target.value;
    setSelectedDateDailyOthers(date);
  };

  useEffect(() => {
    setPrestations((prevState) => ({
      ...prevState,
      price_total: Number(prestations.qty) * Number(prestations.price_unitaire),
    }));
  }, [prestations.qty, prestations.price_unitaire]);

  useEffect(() => {
    setPrestations((prevState) => ({
      ...prevState,
      reste: Number(prestations.price_total) - Number(prestations.avance),
    }));
  }, [prestations.price_total, prestations.avance]);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.put(
        `${server}/commande/update-commande/${id}/${commandeId}`,
        prestations, // Send prestations directly
        { withCredentials: true }
      );
      window.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchPrestation = async () => {
      try {
        const response = await axios.get(
          `${server}/commande/get-commande/${id}/${commandeId}`,
          { withCredentials: true }
        );

        // Populate formData with initial values from prestation
        setPrestations({
          commande: response.data.prestation.commande,
          qty: response.data.prestation.qty,
          price_unitaire: response.data.prestation.price_unitaire,
          price_total: response.data.prestation.price_total,
          avance: response.data.prestation.avance,
          reste: response.data.prestation.reste,
          desc: response.data.prestation.desc,
          relation: response.data.prestation.relation,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchPrestation();
  }, [id, commandeId]);

  return (
    <Box m="20px">
      <FlexBetween>
        <Header
          title="AJOUTER UNE AUTRE COMMANDE "
          subtitle="Ajouter une autre commande sur ce client"
        />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPopup1(false)}
        >
          X
        </Button>
      </FlexBetween>
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="string"
            label={`La commande : ${prestations.commande}`}
            value={prestations.commande}
            onChange={(e) =>
              setPrestations({ ...prestations, commande: e.target.value })
            }
            name="commande"
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label={`La quantité : ${prestations.qty}`}
            value={prestations.qty}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                qty: parseInt(e.target.value),
              })
            }
            name="qty"
            sx={{ gridColumn: "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label={`Prix unitaire : ${prestations.price_unitaire}`}
            value={prestations.price_unitaire}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                price_unitaire: parseInt(e.target.value),
              })
            }
            name="price_unitaire"
            sx={{ gridColumn: "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            readOnly
            type="number"
            label={`Prix total (PT) : ${prestations.price_total}`}
            value={prestations.price_total}
            name="price_total"
            sx={{ gridColumn: "span 4" }}
          />
          {/* <TextField
            fullWidth
            variant="filled"
            type="number"
            label={`Avance : ${prestations.avance}`}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                avance: parseInt(e.target.value),
              })
            }
            name="avance"
            sx={{ gridColumn: "span 4" }}
          /> */}
          <TextField
            fullWidth
            variant="filled"
            readOnly
            type="number"
            label={`Le reste : ${prestations.reste}`}
            value={prestations.reste}
            name="reste"
            sx={{ gridColumn: "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="string"
            label={`Observation : ${prestations.desc}`}
            onChange={(e) =>
              setPrestations({ ...prestations, desc: e.target.value })
            }
            name="desc"
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            id="date"
            label="Sélectionner la Date du rendez-vous"
            type="date"
            value={selectedDateDailyOthers}
            onChange={handleDateChangeDailyOthers}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ gridColumn: "span 2" }}
          />
        </Box>
        <Box display="flex" justifyContent="end" mt="20px">
          <Button type="submit" color="secondary" variant="contained">
            Modifier la commande
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CommandeEdit;
