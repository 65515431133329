import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormPrestation from "scenes/formPrestation";
import { useGetPrestationsQuery, useGetUserQuery } from "state/api";
import axios from "axios";
import { server } from "../../../src/server";
import "./commande.css";
import DataGridCustomToolbarCommande from "components/DataGridCustomToolBarCommande";
import FormMainCommand from "scenes/formMainCommand";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import StatBox from "components/StatBox";
import { MonetizationOn } from "@mui/icons-material";

const DailyCommande = () => {
  const theme = useTheme();
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [dailyCash, setDailyCash] = useState(0);
  const [dailyDepenses, setDailyDepenses] = useState(0);

  const fetchCommandesByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/commandes-by-date/${date}`
      );
      setPrestations(response.data.commandes);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponible");
      setError("Ces données ne sont pas disponible");
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    fetchCommandesByDate(date);
  };

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const [prestations, setPrestations] = useState([]);
  const IdPrestation = localStorage.getItem("prestationId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/all-commandes`);
        const prestationsArray = response.data || [];
        setPrestations(prestationsArray);
        console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [openPopup, setOpenPopup] = useState(false);

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer cette commande?")) {
        const response = await axios.delete(
          `${server}/commande/delete-commande/${id}`
        );
        if (response) {
          toast.success("Commande supprimée avec succès");
          window.location.reload(true);
        } else {
          toast.error("Erreur lors de la suppression de la commande");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Utilisateur introuvable");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dailyCash = await axios.get(
          `${server}/payment-commande/daily-paid-amount-cash`
        );
        setDailyCash(dailyCash.data.netCashAmount);

        const dailyResponseDepense = await axios.get(
          `${server}/commande/api/commande-depense/daily`
        );
        setDailyDepenses(dailyResponseDepense.data.total);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const statusColors = {
    delivered: theme.palette.success.main,
    not_delivered: theme.palette.error.main,
    half_delivered: theme.palette.warning.main,
    not_paid_delivered: theme.palette.grey[500],
    production: theme.palette.info.main,
    pending: theme.palette.grey.main,
    not_production: theme.palette.error.main,
  };

  const statusLabels = {
    delivered: "Livré",
    not_delivered: "Non livré",
    half_delivered: "Livré avec dette",
    not_paid_delivered: "Livré sans paiement",
    production: "En production",
    pending: "En attente de récupération",
    not_production: "Pas encore en production",
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Fait le",
      flex: 1.5,
    },
    {
      field: "nomClient",
      headerName: "Nom Client",
      flex: 2,
    },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "qty",
      headerName: "Quantité",
      flex: 1,
    },
    {
      field: "price_unitaire",
      headerName: "Prix Unitaire",
      flex: 1.5,
    },
    {
      field: "price_total",
      headerName: "Prix Total",
      flex: 2,
    },
    {
      field: "avance",
      headerName: "Paiement",
      flex: 2,
    },
    {
      field: "reste",
      headerName: "Reste",
      flex: 1.5,
    },
    {
      field: "userName",
      headerName: "Créé par",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/commande-details/${params.row.id}`}>
              <Button variant="contained" color="primary" size="small">
                Détails
              </Button>
            </Link>
            <Link to={`/commande-payment/${params.row.id}`}>
              <Button variant="contained" color="secondary" size="small">
                Paiement
              </Button>
            </Link>

            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() => handleDelete(params.row.id)}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Les commandes journalières"
        subtitle="La liste des commandes journalières"
      />
      <FlexBetween>
        <TextField
          id="date"
          label="Sélectionner la Date"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: "20px", width: "250px" }}
        />
        <Box flex="1 1 300px" m="0 1rem">
          <StatBox
            title="L'argent disponible dans la caisse aujourd’hui"
            value={formatNumber(dailyCash)}
            increase="fbu"
            description="Aujourd’hui"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px" m="0 1rem">
          <StatBox
            title="Dépense journalière"
            value={formatNumber(dailyDepenses)}
            increase="fbu"
            description="Depuis 7h30"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </FlexBetween>
      {loading ? (
        <Box display="flex" justifyContent="center" mt="2rem">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={prestations.map((prestation) => {
              const totalQty = prestation.prestations.reduce(
                (sum, item) => sum + (item.qty || 0),
                0
              );
              const totalPriceUnitaire = prestation.prestations.reduce(
                (sum, item) => sum + (item.price_unitaire || 0),
                0
              );
              const totalPriceTotal = prestation.prestations.reduce(
                (sum, item) => sum + (item.price_total || 0),
                0
              );
              const totalAvance = prestation.prestations.reduce(
                (sum, item) => sum + (item.avance || 0),
                0
              );
              const totalReste = prestation.prestations.reduce(
                (sum, item) => sum + (item.reste || 0),
                0
              );

              return {
                id: prestation._id,
                nomClient: prestation.nomClient,
                userName: prestation.userName,
                status: prestation.status,
                contact: prestation.contact,
                commande: prestation.prestations[0]?.commande || "",
                qty: totalQty,
                price_unitaire: formatNumber(totalPriceUnitaire),
                price_total: formatNumber(totalPriceTotal),
                avance: formatNumber(totalAvance),
                reste: formatNumber(totalReste),
                desc: prestation.prestations[0]?.desc || "",
                createdAt: prestation.createdAt.slice(0, 16),
              };
            })}
            columns={columns}
            pageSize={8}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: { openPopup, setOpenPopup },
            }}
          />
        </Box>
      )}
      <Popup
        title="Créer une nouvelle commande"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormMainCommand setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default DailyCommande;
