import axios from "axios";
import { useState, useEffect } from "react";
import { server } from "../../../src/server";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import { Link } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import SendIcon from "@mui/icons-material/Send";

import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import { CheckBox } from "@mui/icons-material";

const Pdf = () => {
  const theme = useTheme();
  const [pdfs, setPdfs] = useState([]);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [open1, setOpen1] = useState(false);

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Veux-tu supprimer ce pdf?")) {
        const response = await axios.delete(
          `${server}/generate-pdf/delete-pdf/${id}`
        );
        if (response.data.success) {
          window.location.reload(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewPaymentDetails = async (id) => {
    try {
      const response = await axios.get(`${server}/generate-pdf/${id}`);
      const pdfData = response.data;

      const doc = new jsPDF();

      // Set document properties
      doc.setProperties({
        title: `proforma-${pdfData.nomClient}`,
        subject: "Facture proforma",
        author: "Bismos",
      });

      // Add logo
      const logoImgData = Logo;
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 30, 30);
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.setFontSize(10);
      doc.text("NIF: 400045190/RC:03436", 10, businessY + 5);
      doc.text("Avenue: KIGARAMA, Ngozi, N0: 21", 10, businessY + 10);
      doc.text("centre Fiscal:Ressort Nord", 10, businessY + 15);
      doc.text("Secteur d'activité: COM. General", 10, businessY + 20);
      doc.text("    ", 10, businessY + 30);

      // Invoice Details
      const invoiceY = businessY + 21;
      doc.setFontSize(16);
      doc.text("Facture Proforma", doc.internal.pageSize.getWidth() - 70, 20); // Top-right corner
      doc.setFontSize(11);
      doc.text(`   `, 10, invoiceY);
      doc.text(`Nom de l'entreprise: ${pdfData.nomClient}`, 10, invoiceY + 5);
      doc.text(
        `Personne de Contact: ${pdfData.personContact}`,
        10,
        invoiceY + 10
      );
      doc.text(
        `Numéro de contact: ${pdfData.personContactNumber}`,
        10,
        invoiceY + 15
      );

      // Add checkboxes below Numero de contact
      const checkboxY = invoiceY + 20; // Adjust Y position as needed
      doc.setFontSize(10);
      doc.text("assujetti à la TVA : ", 10, checkboxY + 2);
      doc.rect(40, checkboxY - 2, 5, 5); // Checkbox 1
      doc.text("Oui", 45, checkboxY + 2);
      doc.rect(52, checkboxY - 2, 5, 5); // Checkbox 2
      doc.text("Non", 57, checkboxY + 2);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );
      doc.text(
        `No du proforma: ${pdfData.invoiceNumber}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY + 5
      );
      doc.text(
        `Validité: un mois`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY + 10
      );

      // Table for Employee Payments
      const tableY = checkboxY + 20;
      const headers = ["Item", "Description", "Prix", "No de page", "couleur"];
      const options = {
        startY: tableY,
        head: [headers],
        body: pdfData.items.map((item) => [
          item.item,
          item.description,
          item.prix,
          item.nombre_page,
          item.color,
        ]),
        styles: {
          fontSize: 10,
          cellPadding: 2,
          overflow: "linebreak",
          halign: "left",
        },
        columnWidth: [80, 40, 80],
      };
      doc.autoTable(options);

      // Total Prices
      const totalY = doc.autoTable.previous.finalY + 10;
      doc.setFont("helvetica", "bold");
      doc.text(
        `     Prix Total hors tax: ${pdfData.priceTotalHorsTax} fbu`,
        10,
        totalY
      );
      doc.text(`     TVA (18%): ${pdfData.priceTVA} fbu`, 10, totalY + 5);
      doc.text(`      TTC: ${pdfData.priceTTC} fbu`, 10, totalY + 10);

      // Footer Text
      const footerY = doc.internal.pageSize.getHeight() - 20;
      const footerText =
        "Tel: 79 967 624 / 22303165 ||KCB:06691079043 ,Bancobu :20002-11109-06571220101-09";
      const footerText2 = "www.bismos.com || E-mail: info@bismos.com";
      doc.setFont("helvetica", "normal");
      doc.text(footerText, doc.internal.pageSize.getWidth() / 2, footerY, {
        align: "center",
      });
      doc.text(footerText2, doc.internal.pageSize.getWidth() / 2, footerY + 5, {
        align: "center",
      });

      setPdfDoc(doc);
      setPdf(pdfData);
      setOpen1(true);
    } catch (err) {
      console.error("Error generating PDF:", err);
      toast.error("An error occurred while generating the PDF");
    }
  };

  const handleDownloadPDF = () => {
    if (pdfDoc) {
      pdfDoc.save(`proforma_${pdf?.nomClient}_${pdf?.invoiceNumber}.pdf`);
    } else {
      console.error("PDF not yet generated");
      toast.warn("No PDF available to download yet.");
    }
  };

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const res = await axios.get(`${server}/generate-pdf/pdfs`);
        setPdfs(res.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPDFs();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID du devis",
      flex: 1,
    },
    {
      field: "nomClient",
      headerName: "Nom du client",
      flex: 2,
    },
    {
      field: "url",
      headerName: "Nom du proforma",
      flex: 3,
    },
    {
      field: "priceTTC",
      headerName: "Prix unitaire",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Fait Par",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Crée le",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleViewPaymentDetails(params.row.id)}
          >
            <img
              src={pdfPicture}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                marginRight: "10px",
                transition: "transform 0.3s",
              }}
            />
          </div>
          <Link to={`/pdf/` + params.row.id}>
            <button className="userListEdit">Edit</button>
          </Link>
          {data?.role === "superadmin" && (
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Les factures proforma"
        subtitle="La liste des factures proforma"
      />
      <Grid style={{ display: "flex", marginTop: "10px" }}>
        <Button
          color="secondary"
          variant="contained"
          style={{
            marginBottom: "6px",
            marginRight: "30px",
            fontWeight: "bold",
          }}
          endIcon={<SendIcon />}
        >
          {" "}
          <Link
            to="/facture"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Voir les factures finales
          </Link>
        </Button>
      </Grid>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={pdfs.map((pdf) => ({
            id: pdf._id, // Assuming _id is the unique identifier
            nomClient: pdf.nomClient,
            url: pdf.url,
            priceTTC: pdf.priceTTC,
            rabatintercalaire: pdf.rabatintercalaire,
            userName: pdf.userName,
            createdAt: pdf.createdAt?.slice(0, 10),
          }))}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Popup
        title="Veux-tu télécharger ce fichier?"
        openPopup={open1}
        setOpenPopup={setOpen1}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen1(!open1) || handleDownloadPDF()}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpen1(false)}
          >
            X
          </Button>
        </FlexBetween>
      </Popup>
    </Box>
  );
};

export default Pdf;
