import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { server } from '../../../src/server'; // Adjust the path as necessary
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from 'state/api';


const CommandDetailsPopUp = ({ setOpenPopup, commandeId, prestationId }) => {
  const [prestation, setPrestation] = useState(null);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  useEffect(() => {
    const fetchPrestationDetails = async () => {
      try {
        const response = await axios.get(
          `${server}/commande/get-commande/${commandeId}/${prestationId}`
        );
        setPrestation(response.data.prestation);
      } catch (error) {
        console.error('Error fetching prestation details:', error);
      }
    };

    fetchPrestationDetails();
  }, [commandeId, prestationId]);

  const translateStatus = (status) => {
    const translations = {
      delivered: 'Livré',
      not_delivered: 'Non livré',
      half_delivered: 'Partiellement livré',
      not_paid_delivered: 'Non payé livré',
      production: 'En production',
      pending: 'En attente',
      not_production: 'Non en production',
    };
    return translations[status] || status;
  };

  return (
    <Box p={2} position="relative">
      {/* Close Button */}
      <IconButton
        aria-label="close"
        onClick={() => setOpenPopup(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      {prestation ? (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            {prestation.commande}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                <strong>Quantité:</strong> {prestation.qty}
              </Typography>
              <Typography>
                <strong>Prix unitaire:</strong> {prestation.price_unitaire} fbu
              </Typography>
              <Typography>
                <strong>Prix total:</strong> {prestation.price_total} fbu
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Avance:</strong> {prestation.avance} fbu
              </Typography>
              <Typography>
                <strong>Reste:</strong> {prestation.reste} fbu
              </Typography>
              <Typography>
                <strong>Assujetti à la TVA:</strong>{' '}
                {prestation.assujettiTVA ? 'Oui' : 'Non'}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Typography>
            <strong>Observation:</strong> {prestation.desc}
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                <PersonIcon fontSize="small" /> {prestation.personContactName}
              </Typography>
              <Typography>
                <PhoneIcon fontSize="small" /> {prestation.personContactPhone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <EventIcon fontSize="small" /> Date du rendez-vous:{' '}
                {prestation.relation}
              </Typography>
              <Typography>
                <strong>Créé le:</strong>{' '}
                {new Date(prestation.createdAt).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Typography>
            <strong>Statut:</strong> {translateStatus(prestation.status)}
          </Typography>
        </Box>
      ) : (
        <Typography>Chargement des détails...</Typography>
      )}
    </Box>
  );
};

export default CommandDetailsPopUp;
